import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
export default {
    name: "Home",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            dashboardData: [],
            topProducts: [['Income', 'Income per Products']],
            topServices: [['Income', 'Income per Services']],
            name: localStorage.getItem('name'),
            optionsServices: {
                width: '100%',
                height: 300,
                backgroundColor: 'transparent',
                borderRadius: 10,
                chartArea: { left: 20, top: 40, right: 20, bottom: 100, width: '90%', height: '90%' },
                colors: ['#800404', '#5D6D7E', '#644A44', '#D5DBDB', '#229954'],
                pieHole: 0.7,
                legend: { position: 'bottom', maxLines: 2, textStyle: { color: 'black', fontSize: 14 } },
                pieSliceText: 'none',
                pieSliceBorderColor: 'none',
                title: 'Top Services'
            },
            optionsProducts: {
                width: '100%',
                height: 300,
                backgroundColor: 'transparent',
                borderRadius: 10,
                chartArea: { left: 20, top: 40, right: 20, bottom: 100, width: '90%', height: '90%' },
                colors: ['#800404', '#5D6D7E', '#644A44', '#D5DBDB', '#229954'],
                pieHole: 0.7,
                legend: { position: 'bottom', maxLines: 2, textStyle: { color: 'black', fontSize: 14 } },
                pieSliceText: 'none',
                pieSliceBorderColor: 'none',
                title: 'Top Products'
            }
        }
    },
    async mounted() {
        await this.getDashboardData();
    },
    methods: {
        getDashboardData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.dashboard.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dashboardData = response.data.data;
                    response.data.data.topFiveProducts.map(el => {
                        this.topProducts.push(el);
                    });
                    response.data.data.topFiveServices.map(el =>{
                        this.topServices.push(el);
                    });
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            });
        }
    }
};
