import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "FooterCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            title: null,
            description: null,
            email: null,
            copyrightText: null,
            emailPlaceholderText: null,
            editor: ClassicEditor,
        }
    },
    async created() {
        document.title = "Cosmo - Footer Two CMS";
        await this.getFooterCMS();
    },
    methods: {
        getFooterCMS: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.footerCMS.footerCmsURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    console.log("response", response);
                    if (!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.title = this.dataList.title;
                        this.description = this.dataList.description;
                        this.email = this.dataList.email;
                        this.copyrightText = this.dataList.copyrightText;
                        this.emailPlaceholderText = this.dataList.emailPlaceholderText;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        checkValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter email!"
                })
                return false;
            }
            if (!this.copyrightText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter copyright text!"
                })
                return false;
            }
            return true;
        },
        update: async function () {
            if (this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                let data = {
                    title: this.title,
                    description: this.description,
                    email: this.email,
                    copyrightText: this.copyrightText,
                    emailPlaceholderText: this.emailPlaceholderText
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.footerCMS.footerCmsURL,
                    data: data,
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                        this.$refs.backgroundImage.value = null;
                        this.$refs.playStoreImage.value = null;
                        this.$refs.appStoreImage.value = null;
                    }
                    this.isLoading = false;
                    this.getFooterCMS();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}