import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";

export default {
    name: "SectionFiveCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            title: "",
            backgroundTitleOne: "",
            backgroundTitleTwo: "",
        }
    },
    async created() {
        document.title = "Cosmo - Section Five CMS";
        await this.fetchData();
    },
    methods: {
        fetchData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.sectionFiveCMS.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if (!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.title = this.dataList.title;
                        this.backgroundTitleOne = this.dataList.backgroundTitleOne;
                        this.backgroundTitleTwo = this.dataList.backgroundTitleTwo;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            return true;
        },
        update: async function () {
            if (this.checkValidation() == true) {
                this.isLoading = true;
                let data = {
                    title: this.title,
                    backgroundTitleOne: this.backgroundTitleOne,
                    backgroundTitleTwo: this.backgroundTitleTwo,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.sectionFiveCMS.requestURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                    }
                    this.isLoading = false;
                    this.fetchData();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}