<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Payment API Credential</h3>
                </div>
            </div>
            <div class="mt-4">
                <form>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-6">
                            <label class="form-label text-blueish">Security Key:</label>
                            <input type="text" v-model="securityKey" class="form-control" placeholder="Enter security key">
                        </div>
                    </div>
                    <div class="mt-5">
                        <button type="button" @click="update()" class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/payment-configuration.js"></script>

<style>
    @import "../css/common.css";
</style>
  
