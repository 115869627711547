<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Contact Page CMS</h3>
                </div>
            </div>
            <div class="mt-4">
                <form>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Title:</label>
                            <input type="text" v-model="title" class="form-control" placeholder="Enter title">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Sub Title:</label>
                            <input type="text" v-model="subTitle" class="form-control" placeholder="Enter sub title">
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Contact No:</label>
                            <input type="text" v-model="contactNo" class="form-control" placeholder="Enter contact number">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Professional Type:</label>
                            <input type="text" v-model="professionalType" class="form-control" placeholder="ex: A,B,C,D">
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Form Title:</label>
                            <input type="text" v-model="formTitle" class="form-control" placeholder="Enter form title">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Form Sub Title:</label>
                            <input type="text" v-model="formSubTitle" class="form-control" placeholder="Enter form sub title">
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-6">
                            <label class="form-label text-blueish">Address:</label>
                            <textarea v-model="address" class="form-control text-site-gray-dark" rows="4" placeholder="Enter address"></textarea>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button type="button" @click="update()" class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/contact-page-cms.js"></script>

<style>
    @import "../css/common.css";
</style>
  
