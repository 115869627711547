<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Home Component UI</h3>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Home Component CMS</h5>
                    <hr />
                    <div class="form-body mt-4" v-if="dataLoaded">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section One Title Font Color:" v-model="pageCMSData.sectionOneTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section One Subtitle Font Color:" v-model="pageCMSData.sectionOneSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Two Header Font Color:" v-model="pageCMSData.sectionTwoHeaderFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Two Title Font Color:" v-model="pageCMSData.sectionTwoTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>

                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Two Sub Title Font Color:" v-model="pageCMSData.sectionTwoSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Three Header Font Color:" v-model="pageCMSData.sectionThreeHeaderFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Three Title Font Color:" v-model="pageCMSData.sectionThreeTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Three SubTitle Font Color:" v-model="pageCMSData.sectionThreeSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Four Title Font Color:" v-model="pageCMSData.sectionFourTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Four SubTitle Font Color:" v-model="pageCMSData.sectionFourSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Five Header Font Color:" v-model="pageCMSData.sectionFiveHeaderFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Five Title Font Color:" v-model="pageCMSData.sectionFiveTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            

                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Five Sub Title Font Color:" v-model="pageCMSData.sectionFiveSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Six Title Font Color:" v-model="pageCMSData.sectionSixTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Six SubTitle Font Color:" v-model="pageCMSData.sectionSixSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Seven Header Font Color:" v-model="pageCMSData.sectionSevenHeaderFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Seven Title Font Color:" v-model="pageCMSData.sectionSevenTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Section Seven SubTitle Font Color:" v-model="pageCMSData.sectionSevenSubTitleFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-3">
                            <!-- <div class="d-grid gap-2 d-md-block">
                                <button @click="updateCms" type="button" class="btn btn-primary">Save</button>
                            </div> -->
                            <div class="d-grid gap-2 d-md-flex justify-content-md-start"> <button class="btn bg-site-darkRed border-site-darkRed text-white px-5 text-uppercase" @click="updateCms" type="button">Update</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/home-ui.js"></script>

<style>
    @import "../css/common.css";
</style>
  
