import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "FooterCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            title: null,
            subTitle: null,
            buttonText: null,
            buttonLink: null,
            footerInfo: null,
            previewBackgroundImage: null,
            backgroundImage: null,
            backgroundImageFile: null,
            previewPlayStoreImage: null,
            playStoreImage: null,
            playStoreImageFile: null,
            previewAppStoreImage: null,
            appStoreImage: null,
            appStoreImageFile: null
        }
    },
    async created() {
        document.title = "Cosmo - Footer CMS";
        await this.getFooterCMS();
    },
    methods: {
        getFooterCMS: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.footerCMS.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.title = this.dataList.title;
                        this.subTitle = this.dataList.subTitle;
                        this.buttonText = this.dataList.buttonText;
                        this.buttonLink = this.dataList.buttonLink;
                        this.footerInfo = this.dataList.footerInfo;
                        this.backgroundImage = this.dataList.backgroundImage;
                        this.playStoreImage = this.dataList.playStoreImage;
                        this.appStoreImage = this.dataList.appStoreImage;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onBackgroundImageChange: function (event) {
            this.backgroundImageFile = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewBackgroundImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onPlayStoreImageChange: function (event) {
            this.playStoreImageFile = event.target.files[0];
            let input = this.$refs.playStoreImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewPlayStoreImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onAppStoreImageChange: function (event) {
            this.appStoreImageFile = event.target.files[0];
            let input = this.$refs.appStoreImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewAppStoreImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            if(!this.subTitle) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter sub title!"
                })
                return false;
            }
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button text!"
                })
                return false;
            }
            if(!this.buttonLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button link!"
                })
                return false;
            }
            if(!this.footerInfo) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter footer info!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("title", this.title);
                formData.append("subTitle", this.subTitle);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                formData.append("footerInfo", this.footerInfo);
                formData.append("backgroundImage", this.backgroundImageFile ? this.backgroundImageFile : this.backgroundImage);
                formData.append("playStoreImage", this.playStoreImageFile ? this.playStoreImageFile : this.playStoreImage);
                formData.append("appStoreImage", this.appStoreImageFile ? this.appStoreImageFile : this.appStoreImage);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.footerCMS.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                        this.$refs.backgroundImage.value = null;
                        this.$refs.playStoreImage.value = null;
                        this.$refs.appStoreImage.value = null;
                    }
                    this.isLoading = false;
                    this.getFooterCMS();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}