import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            id: this.$route.params.id,
            reviewData: {},
            imageFile: null,
            previewImage: null
        }
    },
    async created() {
        await this.getReviewData();
    },
    methods: {
        getReviewData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.customerReview.requestURL + '/' + this.id,
                headers: authHeader()
            }
            await this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.reviewData = response.data.data[0];
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function() {
            if(!this.reviewData.heading) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter heading!"
                })
                return false;
            }
            if(!this.reviewData.reviewerName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter reviewer name!"
                })
                return false;
            }
            if(!this.reviewData.reviewerProfessional) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter reviewer profession!"
                })
                return false;
            }
            if(!this.reviewData.description) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter description!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("heading", this.reviewData.heading);
                formData.append("description", this.reviewData.description);
                formData.append("reviewerName", this.reviewData.reviewerName);
                formData.append("reviewerProfessional", this.reviewData.reviewerProfessional);
                formData.append("rating", this.reviewData.rating);
                formData.append("reviewerImage", this.imageFile ? this.imageFile : this.reviewData.reviewerImage);
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.customerReview.requestURL + '/' + this.id,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.previewImage = "";
                        this.$refs.image.value = null;
                    }
                    this.isLoading = false;
                    this.$router.push("/customer-review");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}