import { createRouter, createWebHashHistory } from 'vue-router';
import Signin from "../components/auth/template/Signin";
import Home from "../components/dashboard/template/Home";
import guard from "../guard";
import HeaderCMS from "../components/header-cms/template/HeaderCMS";
import FooterCMS from "../components/footer-cms/template/FooterCMS";
import FooterCmsTwo from "../components/footer-cms/template/FooterCmsTwo";
import SectionOneCMS from "../components/section-one-cms/template/SectionOneCMS";
import SectionTwoCMS from "../components/section-two-cms/template/SectionTwoCMS";
import DataList from "../components/section-three/template/DataList";
import AddData from "../components/section-three/template/AddData";
import EditData from "../components/section-three/template/EditData";
import SectionFourCMS from "../components/section-four-cms/template/SectionFourCMS";
import SectionFiveCMS from "../components/section-five-cms/template/SectionFiveCMS";
import SectionSixCMS from "../components/section-six-cms/template/SectionSixCMS";
import PaymentConfiguration from "../components/payment-configuration/template/PaymentConfiguration";
import SiteSocialLinks from "../components/site-social-links/template/SiteSocialLinks";
import ReviewList from "../components/review/template/ReviewList";
import AddReview from "../components/review/template/AddReview";
import UnapprovedUserList from "../components/user/template/UnapprovedUserList";
import ApprovedUserList from "../components/user/template/ApprovedUserList";
import RejectedList from "../components/user/template/RejectedList";
// import GlobalPlatformCost from "../components/global-platform-cost/template/GlobalPlatformCost";
// import CustomPlatformCost from "../components/custom-platform-cost/template/CustomPlatformCost";
import OrderList from "../components/order/template/OrderList";
import Admin from "../components/user/template/Admin";
import Client from "../components/user/template/Client";
import OrderDetails from "../components/order/template/OrderDetails";
import Profile from "../components/profile/template/Profile";
import EditReview from "../components/review/template/EditReview";
import SiteCMS from "../components/site-cms/template/SiteCMS";
import AboutCMS from "../components/about-cms/template/AboutCMS";
import GalleryCategory from "../components/gallery/template/GalleryCategory";
import Gallery from "../components/gallery/template/Gallery";
import ContactPageCMS from "../components/contact-page-cms/template/ContactPageCMS";
import ContactList from "../components/contact-page-cms/template/ContactList";
// import PlatformFeeList from "../components/platform-fee-log/template/PlatformFeeList";
import CosmoExpertCMS from "../components/cosmo-expert-cms/template/CosmoExpertCMS";
import ExpertList from "../components/cosmo-expert/template/ExpertList";
import AddExpert from "../components/cosmo-expert/template/AddExpert";
import EditExpert from "../components/cosmo-expert/template/EditExpert";
import ServiceCategory from "../components/service/template/ServiceCategory";
import ServiceSubCategory from "../components/service/template/ServiceSubCategory";
import ServiceColor from "../components/service/template/ServiceColor";
import Studio from "../components/studio/template/Studio";
import PageTitleCMS from "../components/page-title-cms/template/PageTitleCMS";
import HomeUI from "../components/Component CMS/home/template/HomeUI";
import AboutUI from "../components/Component CMS/about/template/AboutUI";
import ProfessionalServiceUI from "../components/Component CMS/professionalServices/template/ProfessionalServiceUI";
import LocationComponentUI from "../components/Component CMS/location/template/LocationComponentUI";
import CosmoProfessionalUI from "../components/Component CMS/cosmoProfessional/template/CosmoProfessionalUI";
import ContactComponentUI from "../components/Component CMS/contact/template/ContactComponentUI";
import ProfessionalSubServiceUI from "../components/Component CMS/professionalSubServices/template/ProfessionalSubServiceUI";
import ProfessionalTermsAndConditionCMS from "../components/professional-terms-condition/template/ProfessionalTermsAndConditionCMS";
import ClientTermsAndConditionCMS from "../components/client-terms-condition/template/ClientTermsAndConditionCMS";
import AddStudio from "../components/studio/template/AddStudio";
import EditStudio from "../components/studio/template/EditStudio";

let routes = [
    { path: "/signin", name: "Signin", component: Signin },
    { path: "/", redirect: '/dashboard' },
    { path: "/dashboard", name: "Home", component: Home, beforeEnter: guard },
    { path: "/header-cms", name: "HeaderCMS", component: HeaderCMS, beforeEnter: guard },
    { path: "/footer-cms", name: "FooterCMS", component: FooterCMS, beforeEnter: guard },
    { path: "/footer-tow-cms", name: "FooterCmsTwo", component: FooterCmsTwo, beforeEnter: guard },
    { path: "/section-one-cms", name: "SectionOneCMS", component: SectionOneCMS, beforeEnter: guard },
    { path: "/section-two-cms", name: "SectionTwoCMS", component: SectionTwoCMS, beforeEnter: guard },
    { path: "/section-three-cms", name: "DataList", component: DataList, beforeEnter: guard },
    { path: "/add-section-three", name: "AddData", component: AddData, beforeEnter: guard },
    { path: "/edit-section-three/:id", name: "EditData", component: EditData, beforeEnter: guard },
    { path: "/section-four-cms", name: "SectionFourCMS", component: SectionFourCMS, beforeEnter: guard },
    { path: "/section-five-cms", name: "SectionFiveCMS", component: SectionFiveCMS, beforeEnter: guard },
    { path: "/section-six-cms", name: "SectionSixCMS", component: SectionSixCMS, beforeEnter: guard },
    { path: "/site-cms", name: "SiteCMS", component: SiteCMS, beforeEnter: guard },
    { path: "/about-cms", name: "AboutCMS", component: AboutCMS, beforeEnter: guard },
    { path: "/payment-config", name: "PaymentConfiguration", component: PaymentConfiguration, beforeEnter: guard },
    { path: "/site-social-links", name: "SiteSocialLinks", component: SiteSocialLinks, beforeEnter: guard },
    { path: "/customer-review", name: "ReviewList", component: ReviewList, beforeEnter: guard },
    { path: "/add-customer-review", name: "AddReview", component: AddReview, beforeEnter: guard },
    { path: "/unapproved-user", name: "UnapprovedUserList", component: UnapprovedUserList, beforeEnter: guard },
    { path: "/approved-user", name: "ApprovedUserList", component: ApprovedUserList, beforeEnter: guard },
    { path: "/rejected-user", name: "RejectedList", component: RejectedList, beforeEnter: guard },
    // { path: "/global-platform-cost", name: "GlobalPlatformCost", component: GlobalPlatformCost, beforeEnter: guard },
    // { path: "/custom-platform-fee", name: "CustomPlatformCost", component: CustomPlatformCost, beforeEnter: guard },
    { path: "/orders", name: "OrderList", component: OrderList, beforeEnter: guard },
    { path: "/admin", name: "Admin", component: Admin, beforeEnter: guard },
    { path: "/client", name: "Client", component: Client, beforeEnter: guard },
    { path: "/order-details/:id", name: "OrderDetails", component: OrderDetails, beforeEnter: guard },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard  },
    { path: "/edit-review/:id", name: "EditReview", component: EditReview, beforeEnter: guard },
    { path: "/gallery-category", name: "GalleryCategory", component: GalleryCategory, beforeEnter: guard },
    { path: "/gallery", name: "Gallery", component: Gallery, beforeEnter: guard },
    { path: "/contact-cms", name: "ContactPageCMS", component: ContactPageCMS, beforeEnter: guard },
    { path: "/contact-list", name: "ContactList", component: ContactList, beforeEnter: guard },
    { path: "/service-category", name: "ServiceCategory", component: ServiceCategory, beforeEnter: guard },
    { path: "/service-sub-category", name: "ServiceSubCategory", component: ServiceSubCategory, beforeEnter: guard },
    { path: "/service-color", name: "ServiceColor", component: ServiceColor, beforeEnter: guard },
    // { path: "/platform-fee-list", name: "PlatformFeeList", component: PlatformFeeList, beforeEnter: guard },
    { path: "/cosmo-expert-cms", name: "CosmoExpertCMS", component: CosmoExpertCMS, beforeEnter: guard },
    { path: "/cosmo-expert", name: "ExpertList", component: ExpertList, beforeEnter: guard },
    { path: "/studio", name: "Studio", component: Studio, beforeEnter: guard },
    { path: "/add-studio", name: "AddStudio", component: AddStudio, beforeEnter: guard },
    { path: "/edit-studio/:id", name: "EditStudio", component: EditStudio, beforeEnter: guard },
    { path: "/add-cosmo-expert", name: "AddExpert", component: AddExpert, beforeEnter: guard },
    { path: "/edit-expert/:id", name: "EditExpert", component: EditExpert, beforeEnter: guard },
    { path: "/home-component-cms", name: "HomeUI", component: HomeUI, beforeEnter: guard },
    { path: "/about-component-cms", name: "AboutUI", component: AboutUI, beforeEnter: guard },
    { path: "/page-title-cms", name: "PageTitleCMS", component: PageTitleCMS, beforeEnter: guard },
    { path: "/professional-service-component-cms", name: "ProfessionalServiceUI", component: ProfessionalServiceUI, beforeEnter: guard },
    { path: "/professional-sub-service-component-cms", name: "ProfessionalSubServiceUI", component: ProfessionalSubServiceUI, beforeEnter: guard },
    { path: "/location-component-cms", name: "LocationComponentUI", component: LocationComponentUI, beforeEnter: guard },
    { path: "/cosmo-professional-component-cms", name: "CosmoProfessionalUI", component: CosmoProfessionalUI, beforeEnter: guard },
    { path: "/contact-component-cms", name: "ContactComponentUI", component: ContactComponentUI, beforeEnter: guard },
    { path: "/client-terms-condition", name: "ClientTermsAndConditionCMS", component: ClientTermsAndConditionCMS, beforeEnter: guard },
    { path: "/professional-terms-condition", name: "ProfessionalTermsAndConditionCMS", component: ProfessionalTermsAndConditionCMS, beforeEnter: guard },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router;
