<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Edit Expert</h3>
                </div>
            </div>
            <div class="mt-4">
                <form>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6">
                            <label for="file" class="add_product_file_upload">Upload Image</label>
                            <input type="file" id="file" ref="image" @change="onImageChange($event)" class="d-none">
                        </div>
                        <div class="col-12 col-sm-6">
                            <label class="form-label text-blueish">UPLOADING : &nbsp;</label>
                            <img v-if="previewImage" :src="previewImage" height="100" width="150"/>
                            <img v-else :src="$imageURL + 'cosmo-expert/' + expertData.image" height="100" width="150"/>
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Name:</label>
                            <input type="text" v-model="expertData.name" class="form-control">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Special In:</label>
                            <input type="text" v-model="expertData.specialIn" class="form-control">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Link:</label>
                            <input type="text" v-model="expertData.link" class="form-control">
                        </div>
                    </div>
                    <div class="mt-5">
                        <button type="button" @click="update()" class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/edit-expert.js"></script>

<style>
    @import "../css/add-expert.css";
</style>
  
