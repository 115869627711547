import Loading from "vue-loading-overlay";
// import {authHeader} from "../../../auth";

export default {
    name: "AddReview",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            heading: null,
            description: null,
            previewImage: null,
            imageFile: null,
            reviewerName: null,
            reviewerProfessional: null,
            rating: 0
        }
    },
    async created() {
        document.title = "Cosmo Salon - Add Review";
    },
    methods: {
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function() {
            if(!this.heading) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter heading!"
                })
                return false;
            }
            if(!this.reviewerName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter reviewer name!"
                })
                return false;
            }
            if(!this.reviewerProfessional) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter reviewer profession!"
                })
                return false;
            }
            if(!this.description) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter description!"
                })
                return false;
            }
            if(!this.imageFile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload reviewer image!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("heading", this.heading);
                formData.append("description", this.description);
                formData.append("reviewerName", this.reviewerName);
                formData.append("reviewerProfessional", this.reviewerProfessional);
                formData.append("rating", this.rating);
                formData.append("reviewerImage", this.imageFile);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.customerReview.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.heading = "";
                        this.description = "";
                        this.reviewerName = "";
                        this.reviewerProfessional = "";
                        this.rating = 0;
                        this.previewImage = "";
                        this.$refs.image.value = null;
                    }
                    this.isLoading = false;
                    this.$router.push("/customer-review");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}