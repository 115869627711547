<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Studio</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0"></ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Add New Studio</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label">
                        Studio Name:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="studioName"
                        class="form-control"
                        placeholder="Enter studio name"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">
                        Location Name:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="locationName"
                        class="form-control"
                        placeholder="Enter location name"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label">
                        Email:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="email"
                        class="form-control"
                        placeholder="Enter email"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">
                        Contact No:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="contactNo"
                        class="form-control"
                        placeholder="Enter contact no"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label">
                        Description:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="description"
                        class="form-control"
                        placeholder="Enter description"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label">
                        Image:
                        <span style="color: red; font-size: 18px">*</span>
                        <strong>[Preferred Image Size: 512X560, Max Upload Limit: 1MB]</strong>
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        @change="uploadImage"
                        ref="addImage"
                        id="formFile"
                      />
                    </div>
                    <div class="col-md-6">
                      <img v-if="previewImage" :src="previewImage" height="90" width="150" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">
                      Location Address:
                      <span style="color: red; font-size: 18px">*</span>
                    </label>
                    <GMapAutocomplete
                      @place_changed="handleAddressLatLng"
                      :value="addressLocation"
                      :class="'form-control'"
                    ></GMapAutocomplete>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label">
                        Studio Map Link:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <GMapMap
                        :center="center"
                        :zoom="5"
                        map-type-id="terrain"
                        style="width: 876px; height: 736px"
                      >
                        <GMapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :clickable="true"
                          :draggable="true"
                          @drag="setPlace($event)"
                        ></GMapMarker>
                      </GMapMap>
                    </div>
                  </div>
                  <div>
                    <button v-on:click="addEntity()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script src="../js/add-studio.js"></script>