import ChangePassword from "../template/ChangePassword";
import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";

export default {
    name: "Profile",
    components: {
        Loading,
        ChangePassword
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            userDetails: {},
            id: '',
            email: ''
        }
    },
    async mounted() {
        document.title = "Cosmo Salon - Profile";
        this.id = localStorage.getItem("id");
        this.email = localStorage.getItem("email");
        await this.getUserInfo();
    },
    methods: {
        getUserInfo: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.auth.getProfileURL,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.userDetails = await response.data.data.data;
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            let data = {
                fullName: this.userDetails.fullName,
                city: this.userDetails.city,
                zipCode: this.userDetails.zipCode,
                phoneNo: this.userDetails.phoneNo,
                streetAddress: this.userDetails.streetAddress
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.auth.updateProfile,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    localStorage.setItem("name", this.userDetails.fullName);
                    localStorage.setItem("email", this.userDetails.email);
                    localStorage.setItem("mobile", this.userDetails.phoneNo);
                    await this.getUserInfo();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: "Profile updated successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
