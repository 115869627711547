import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            id: this.$route.params.id,
            singleData: {},
            imageFile: null,
            previewImage: null,
            logoFile: null,
            previewLogo: null
        }
    },
    async created() {
        await this.getData();
    },
    methods: {
        getData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.sectionThreeCMS.requestURL + '/' + this.id,
                headers: authHeader()
            }
            await this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.singleData = response.data.data;
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onLogoChange: function (event) {
            this.logoFile = event.target.files[0];
            let input = this.$refs.logo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewLogo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.singleData.title);
            formData.append("subTitle", this.singleData.subTitle);
            formData.append("logo", this.logoFile ? this.logoFile : this.singleData.logo);
            formData.append("image", this.imageFile ? this.imageFile : this.singleData.image);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.sectionThreeCMS.requestURL + '/' + this.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', this.$swal.stopTimer)
                          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.previewImage = "";
                    this.$refs.image.value = null;
                }
                this.isLoading = false;
                this.$router.push("/section-three-cms");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}