import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            setURL: '',
            dataList: [],
            homePageTitle: '',
            aboutPageTitle: '',
            loginPageTitle: '',
            registrationPageTitle: '',
            contactPageTitle: '',
            locationPageTitle: '',
            expertsPageTitle: '',
            professionalServicesPageTitle: '',
            professionalSubServicePageTitle: ''
        }
    },
    async mounted() {
        document.title = "Cosmo - Page Title CMS";
        this.setURL = this.$serverURL + this.$api.pageTitleCMS.requestURL;
        await this.fetchData();
    },
    methods: {
        fetchData: async function() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.homePageTitle= this.dataList.homePageTitle;
                        this.aboutPageTitle= this.dataList.aboutPageTitle;
                        this.loginPageTitle= this.dataList.loginPageTitle;
                        this.registrationPageTitle= this.dataList.registrationPageTitle;
                        this.contactPageTitle= this.dataList.contactPageTitle;
                        this.locationPageTitle= this.dataList.locationPageTitle;
                        this.expertsPageTitle= this.dataList.expertsPageTitle;
                        this.professionalServicesPageTitle= this.dataList.professionalServicesPageTitle;
                        this.professionalSubServicePageTitle = this.dataList.professionalSubServicePageTitle
                    }
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        update: async function() {
            let data = {
                homePageTitle: this.homePageTitle,
                aboutPageTitle: this.aboutPageTitle,
                loginPageTitle: this.loginPageTitle,
                registrationPageTitle: this.registrationPageTitle,
                contactPageTitle: this.contactPageTitle,
                locationPageTitle: this.locationPageTitle,
                expertsPageTitle: this.expertsPageTitle,
                professionalServicesPageTitle: this.professionalServicesPageTitle,
                professionalSubServicePageTitle: this.professionalSubServicePageTitle
            };
            let config = {
                method: "POST",
                url: this.setURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.fetchData();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}