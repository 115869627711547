<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h3 class="fw-600">Gallery Category</h3>
                </div>
                <div>
                    <button class="btn add_service_btn" data-bs-target="#addAdminModal" data-bs-toggle="modal">Add Category</button>
                </div>
            </div>
            <div style="margin-top: 5%;">
                <div class="card p-3 shadow border-0 round-17">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Category List</h5>
                    </div>
                    <div class="table-container">
                        <div class="table-section">
                            <div class="table-responsive">
                                <table class="custom_table custom_table_header_white">
                                    <thead>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Name</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(row, index) in dataList" :key="index">
                                            <td> {{ index + 1 }} </td>
                                            <td> {{ row['name'] }} </td>
                                            <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                            <td> 
                                                <button v-on:click="setId(index)" class="btn btn-sm btn-success action_btn" data-bs-target="#updateAdminModal" data-bs-toggle="modal">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button v-on:click="deleteEntity(row['id'])" class="btn btn-sm btn-danger action_btn">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-warning" role="alert" style="text-align: center">
                                                    No data found
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
                            <v-pagination v-if="dataList.length > 0" v-model="page" @update:modelValue="updateHandler" :pages="totalPage" :range-size="1" active-color="#212529"></v-pagination>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="addAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content round-20">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addAdminModalLabel">Add New Category
                            <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
                        </h5>
                        <button type="button" id="closeButton" class="btn-close text-site-dark" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-4">
                            <div class="col-12 col-sm-12">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">Name<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input type="text" v-model="name" class="form-control rounded-0" placeholder="Enter name">
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-3">
                        <button type="button" @click="addEntity"
                            class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Edit Modal -->
        <div class="modal fade" id="updateAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="updateAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content round-20">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="updateAdminModalLabel">Update
                            Category
                            <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
                        </h5>
                        <button type="button" id="buttonClose" class="btn-close text-site-dark" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-4">
                            <div class="col-12 col-sm-12">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">Name<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input type="text" v-model="singleData.name" class="form-control rounded-0">
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-3">
                        <button type="button" v-on:click="updateEntity(singleData.id)"
                            class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Update</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Edit Modal -->
    </div>
</template>

<script src="../js/gallery-category.js"></script>

<style scoped>
    @import "../css/common.css";
    @import url("../../../assets/css/pagination.css");
</style>