import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import html2pdf from 'html2pdf.js';
export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            id: this.$route.params.id,
            orderDetails: {},
            billingAddress: {},
            itemList: [],
            professionalDetails: {},
            paidAmount: 0
        }
    },
    async created() {
        await this.getOrderDetails();
    },
    methods: {
        getOrderDetails: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.orderDetailsURL + '/' + this.id,
                headers: authHeader()
            }
            await this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.orderDetails = response.data.data.invoice[0];
                    this.billingAddress = JSON.parse(this.orderDetails.shippingAddress);
                    this.professionalDetails = response.data.data.professionalDetails;
                    this.itemList = response.data.data.products.concat(response.data.data.services);
                    if (this.orderDetails?.paymentStatus && (this.orderDetails.paymentStatus == 'Partial Paid' || this.orderDetails.paymentStatus == 'Pending')) {
                        this.paidAmount = this.orderDetails.paymentAmount
                            ? (parseFloat(this.orderDetails.total) -
                                parseFloat(
                                    this.orderDetails.paymentAmount
                                        ? this.orderDetails.paymentAmount
                                        : 0
                                ))
                            : parseFloat(this.orderDetails.total)
                    } else {
                        this.paidAmount = 0;
                    }
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        generatePDF: function () {
            const doc = document.getElementById('doc');
            var opt = {
                filename: this.orderDetails.invoiceId + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 3 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: 'avoid-all', before: '#page2el' }
            };
            html2pdf().from(doc).set(opt).save();
        }
    }
}