import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "SectionSixCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            title: null,
            description: null,
            buttonText: null,
            buttonLink: null,
            previewImage: null,
            image: null,
            imageFile: null,
            backgroundTitle: ""
        }
    },
    async created() {
        document.title = "Cosmo - Section Six CMS";
        await this.fetchData();
    },
    methods: {
        fetchData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.sectionSixCMS.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.title = this.dataList.title;
                        this.description = this.dataList.description;
                        this.buttonText = this.dataList.buttonText;
                        this.buttonLink = this.dataList.buttonLink;
                        this.image = this.dataList.image;
                        this.backgroundTitle = this.dataList.backgroundTitle;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button text!"
                })
                return false;
            }
            if(!this.buttonLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button link!"
                })
                return false;
            }
            if(!this.description) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter description!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("title", this.title);
                formData.append("description", this.description);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                formData.append("backgroundTitle", this.backgroundTitle);
                formData.append("image", this.imageFile ? this.imageFile : this.image);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.sectionSixCMS.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                        this.$refs.image.value = null;
                    }
                    this.isLoading = false;
                    this.fetchData();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}