<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h3 class="fw-600">Cosmo Expert</h3>
                </div>
                <div>
                    <button @click="$router.push('/add-cosmo-expert')" type="button" class="btn add_service_btn">Add New Expert</button>
                </div>
            </div>
            <div style="margin-top: 5%;">
                <div class="card p-3 shadow border-0 round-17">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Cosmo Expert List</h5>
                    </div>
                    <div class="table-container">
                        <div class="table-section">
                            <div class="table-responsive">
                                <table class="custom_table custom_table_header_white">
                                    <thead>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Name</th>
                                            <th>Image</th>
                                            <th>Special In</th>
                                            <th>Link</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(row, index) in dataList" :key="index">
                                            <td> {{ index + 1 }} </td>
                                            <td> {{ row['name'] }} </td>
                                            <td> <img :src="$imageURL + 'cosmo-expert/' + row.image" height="50"> </td>
                                            <td> {{ row['specialIn'] }} </td>
                                            <td> {{ row['link'] }} </td>
                                            <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                            <td> 
                                                <button title="edit" @click="$router.push('/edit-expert/' + row.id)" class="btn btn-sm btn-success action_btn" data-bs-target="#updateAdminModal" data-bs-toggle="modal">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button title="delete" v-on:click="deleteEntity(row['id'])" class="btn btn-sm btn-danger action_btn">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="7">
                                                <div class="alert alert-warning" role="alert" style="text-align: center">
                                                    No data found
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
                            <v-pagination v-if="dataList.length > 0" v-model="page" @update:modelValue="updateHandler" :pages="totalPage" :range-size="1" active-color="#212529"></v-pagination>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/expert-list.js"></script>

<style scoped>
    @import "../css/expert-list.css";
    @import url("../../../assets/css/pagination.css");
</style>