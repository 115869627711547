<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="fw-600">Service Sub Category</h3>
        </div>
        <div>
          <button
            class="btn add_service_btn"
            data-bs-target="#addAdminModal"
            data-bs-toggle="modal"
          >
            add
            Sub Category
          </button>
        </div>
      </div>
      <div style="margin-top: 5%;">
        <div class="card p-3 shadow border-0 round-17">
          <div class="modal-header border-0">
            <h5
              class="modal-title text-site-dark fs-20 fw-600 ff-poppins400"
              id
            >Service Sub Category List</h5>
          </div>
          <div class="table-container">
            <div class="table-section">
              <div class="table-responsive">
                <table class="custom_table custom_table_header_white">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Image</th>
                      <th>Parent Category</th>
                      <th>Created At</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody v-if="subCategoryList.length > 0">
                    <tr v-for="(data, index) in subCategoryList" :key="index">
                      <td>{{index + 1}}</td>
                      <td>{{data.subCategoryName}}</td>
                      <td>
                        <img :src="imagePath + data.subCategoryImage" height="50" width="50" alt />
                      </td>
                      <td>{{data.categoryName}}</td>
                      <td>{{$filters.formatDate(data.createdAt)}}</td>
                      <td>
                        <button
                          title="edit"
                          class="btn btn-sm btn-success action_btn"
                          @click="setSingleItem(data)"
                          data-bs-target="#updateAdminModal"
                          data-bs-toggle="modal"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button
                          title="delete"
                          @click="deleteSubCategory(data.id)"
                          class="btn btn-sm btn-danger action_btn"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <div
                          class="alert alert-warning"
                          role="alert"
                          style="text-align: center"
                        >No data found</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <nav
            class="page-navigation justify-content-center d-flex mt-3"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="subCategoryList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addAdminModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="addAdminModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5
              class="modal-title text-site-dark fs-20 fw-600 ff-poppins400"
              id="addAdminModalLabel"
            >
              Add
              Service Sub Category
              <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
            </h5>
            <button
              type="button"
              class="btn-close text-site-dark"
              id="closeAdd"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <div class="col-12 col-sm-12 mb-4">
                <label class="form-label text-site-dark ff-poppins400 fs-17">
                  Name:
                  <span style="color: red; font-size: 18px">*</span>
                </label>
                <input
                  v-model="name"
                  type="text"
                  class="form-control rounded-0"
                  placeholder="Enter name"
                />
              </div>
              <div class="col-12 col-sm-12 mb-4">
                <label class="form-label text-site-dark ff-poppins400 fs-17">
                  Select Category:
                  <span style="color: red; font-size: 18px">*</span>
                </label>
                <select class="form-select" id="inputVendor" v-model="category">
                  <option style="display: none" selected value="">Select Category</option>
                  <option v-for="(data, index) in categoryList" :key="index" :value="{categoryId: data.categoryId, categoryName:data.categoryName}">{{data.categoryName}}</option>
                </select>
              </div>
              <div class="col-12 col-sm-12 mb-2">
                <label class="form-label">
                  Image:
                  <strong>
                    [Preferred Image Size: 512X560, Max Upload Limit:
                    1MB]
                  </strong>
                </label>
                <input
                  class="form-control"
                  type="file"
                  @change="uploadImage"
                  accept="image/*"
                  ref="addImage"
                  id="formFile"
                />
              </div>
              <div class="col-12 col-sm-12 mb-2">
                <img v-if="previewImage" :src="previewImage" height="90" width="150" />
              </div>
            </div>
          </div>
          <div class="text-center p-3">
            <button
              type="button"
              @click="addSubCategory"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2"
            >Create</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="updateAdminModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="updateAdminModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5
              class="modal-title text-site-dark fs-20 fw-600 ff-poppins400"
              id="updateAdminModalLabel"
            >
              Update
              Service Sub Category
              <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
            </h5>
            <button
              type="button"
              class="btn-close text-site-dark"
              id="closeUpdate"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <div class="col-12 col-sm-12 mb-2">
                <label class="form-label text-site-dark ff-poppins400 fs-17">
                  Name
                  <span style="color: red; font-size: 18px">*</span>
                </label>
                <input
                  type="text"
                  v-model="singleSubCategory.subCategoryName"
                  class="form-control rounded-0"
                  placeholder="Enter name"
                />
              </div>
              <div class="col-12 col-sm-12 mb-4">
                <label class="form-label text-site-dark ff-poppins400 fs-17">
                  Select Category:
                  <span style="color: red; font-size: 18px">*</span>
                </label>
                <select class="form-select" id="inputVendor" v-model="updateParentCategory">
                  <option style="display: none" selected value="">Select Category</option>
                  <option v-for="(data, index) in categoryList" :key="index" :value="{categoryId: data.categoryId, categoryName:data.categoryName}">{{data.categoryName}}</option>
                </select>
              </div>
              <div class="col-12 col-sm-12 mb-2">
                <label class="form-label">
                  Image:
                  <strong>
                    [Preferred Image Size: 512X560, Max Upload Limit:
                    1MB]
                  </strong>
                </label>
                <input
                  class="form-control"
                  type="file"
                  @change="updateImage"
                  accept="image/*"
                  ref="updateImage"
                  id="formFile"
                />
              </div>
              <div class="col-12 col-sm-12 mb-2">
                <img v-if="previewImage" :src="previewImage" height="90" width="150" />
                <img v-else :src="imagePath + singleSubCategory.subCategoryImage" height="90" width="150" />
              </div>
            </div>
          </div>
          <div class="text-center p-3">
            <button
              type="button"
              @click="updateCategory"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2"
            >Update</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Modal -->
  </div>
</template>

<script src="../js/service-sub-category.js">
</script>

<style scoped>
@import "../css/add_service_cat.css";
@import url("../../../assets/css/pagination.css");
</style>