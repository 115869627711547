import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import * as XLSX from 'xlsx';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            orderList: [],
            sheets: [],
            invoiceList: [],
            professionalList: [],
            clientList: [],
            filter: {
                invoiceId: "",
                startDate: "",
                endDate: "",
                clientId: "",
                professionalId: ""
            },
            page: 1,
            limit: 10,
            totalPage: 0
        };
    },
    async created() {
        document.title = "Cosmo Salon - Orders";
        let params = '?page=' + this.page + '&limit=' + this.limit + '&invoiceId=' + this.filter.invoiceId + "&clientId=" + this.filter.clientId + "&professionalId=" + this.filter.professionalId + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.order.orderListURL + params,
            headers: authHeader()
        }
        await this.getOrderList(config);
        await this.getProfessionalList();
        await this.getClientList();
    },
    methods: {
        getProfessionalList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.professionalListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if (this.professionalList.length == 0) {
                        this.professionalList = response.data.data.map(el => {
                            return { label: el.fullName, value: el.id }
                        })
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        getClientList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.clientListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if (this.clientList.length == 0) {
                        this.clientList = response.data.data.map(el => {
                            return { label: el.fullName, value: el.id }
                        })
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit + '&invoiceId=' + this.filter.invoiceId + "&clientId=" + this.filter.clientId + "&professionalId=" + this.filter.professionalId + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.orderListURL + params,
                headers: authHeader()
            }
            await this.getOrderList(config);
        },
        getOrderList: async function(config) {
            this.isLoading = true;
            await this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.orderList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                    if (this.invoiceList.length == 0) {
                        this.invoiceList = response.data.data.data.map(el => {
                            return { label: el.invoiceId, value: el.invoiceId }
                        })
                    }
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        exportData: function () {
            this.orderList.map((el) => {
                this.sheets.push({
                    'Id': el.invoiceId,
                    'Customer Name': el.customerName,
                    'Created By': el.createdByName,
                    "Payment Status": el.paymentStatus,
                    "Payment Method Name": el.paymentMethodName,
                    "Total Amount": el.total,
                    "Paid Amount": el.paymentAmount,
                    "Status": el.status,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'invoice_report(' + date + ').xlsx');
            this.sheets = [];
        },
        validateFilter: async function () {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select end date!"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select start date!"
                        });
                        return;
                    }
                }
                if (this.filter.invoiceId == null) {
                    this.filter.invoiceId = "";
                }
                document.getElementById('filterClose').click();
                await this.updateHandler();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async deleteOrder(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.order.orderDetailsURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.$axios(config).then((response) => {
                            if (response.data.deleted == true) {
                                this.updateHandler();
                                this.$swal.fire({
                                    icon: "warning",
                                    text: "Order deleted Successfully."
                                });
                            }
                           
                        }).catch(error => {
                           
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        clearFilter: async function () {
            this.filter = {
                invoiceId: "",
                startDate: "",
                endDate: "",
                clientId: "",
                professionalId: ""
            }
        }
    }
};
