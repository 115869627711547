<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Location Component UI</h3>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Location Component CMS</h5>
                    <hr />
                    <div class="form-body mt-4" v-if="dataLoaded">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Header Font Color:" v-model="pageCMSData.headerFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Location Name Font Color:" v-model="pageCMSData.locationNameFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Studio Name Font Color:" v-model="pageCMSData.studioNameFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>

                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Email Font Color:" v-model="pageCMSData.emailFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Contact Font Color:" v-model="pageCMSData.contactFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Description Font Color:" v-model="pageCMSData.descriptionFontColor" :clearable="true"
                                        :bottomBar="true" :colors="colors" />
                                </div>
                            </div>

                        </div>
                        <div class="row justify-content-center mt-3">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-start"> <button class="btn bg-site-darkRed border-site-darkRed text-white px-5 text-uppercase" @click="updateCms" type="button">Update</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/location-component-ui.js"></script>

<style>
    @import "../css/common.css";
</style>
  
