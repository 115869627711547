<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h3 class="fw-600">Professional</h3>
                </div>
            </div>
            <div style="margin-top: 5%;">
                <div class="card p-3 shadow border-0 round-17">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Unapproved Professional List</h5>
                    </div>
                    <div class="table-container">
                        <div class="table-section">
                            <div class="table-responsive">
                                <table class="custom_table custom_table_header_white">
                                    <thead>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone No</th>
                                            <th>City</th>
                                            <th>Country</th>
                                            <th>User Type</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(row, index) in dataList" :key="index">
                                            <td> {{ index + 1 }} </td>
                                            <td> {{ row['fullName'] }} </td>
                                            <td> {{ row['email'] }} </td>
                                            <td> {{ row['phoneNo'] }} </td>
                                            <td> {{ row['city'] }} </td>
                                            <td> {{ row['country'] }} </td>
                                            <td> {{ row['userType'] }} </td>
                                            <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                            <td> 
                                                <button v-on:click="approveUser(row['id'])" class="btn btn-sm btn-success action_btn">
                                                    <i class="bi bi-person-check"></i>
                                                </button>
                                                <button @click="setId(row.id)" data-bs-target="#addAdminModal"
                                                    data-bs-toggle="modal" class="btn btn-sm btn-danger action_btn">
                                                    <i class="bi bi-person-x"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="9">
                                                <div class="alert alert-warning" role="alert" style="text-align: center">
                                                    No data found
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
                            <v-pagination v-if="dataList.length > 0" v-model="page" @update:modelValue="updateHandler" :pages="totalPage" :range-size="1" active-color="#212529"></v-pagination>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="addAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content round-20">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addAdminModalLabel"> Rejection Reason
                            <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
                        </h5>
                        <button type="button" class="btn-close text-site-dark" id="closeAdd" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-4">
                            <div class="col-12 col-sm-12 mb-2">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">Reason<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input v-model="rejectionReason" type="text" class="form-control rounded-0"
                                    placeholder="Enter rejection reason">
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-3">
                        <button type="button" @click="rejectUser"
                            class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Reject</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/unapproved-user-list.js"></script>

<style scoped>
    @import "../css/unapproved-user-list.css";
    @import url("../../../assets/css/pagination.css");
</style>