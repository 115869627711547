import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "ServiceCategory",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            name: "",
            categoryList: [],
            singleCategory: [],
            imagePath: this.$imageURL + 'service-category/',
            page: 1,
            limit: 10,
            totalPage: 0,
            previewImage: '',
            image: '',
            updatePhoto: ''
        }
    },
    async created() {
        document.title = "Cosmo Salon - Service Category";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.service.category.requestURL + params,
            headers: authHeader()
        };
        await this.getCategoryList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.category.requestURL + params,
                headers: authHeader()
            };
            await this.getCategoryList(config);
        },
        uploadImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateImage(event) {
            this.updatePhoto = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getCategoryList: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addCategory: async function () {
            this.isLoading = true;
            if (!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert category name!"
                });
                this.isLoading = false;
                return false;
            } else if(!this.image) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload category image!"
                });
                this.isLoading = false;
                return false;
            }
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('image', this.image);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.service.category.requestURL,
                data: formData,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.name = "";
                }
                this.isLoading = false;
                this.$refs.addImage.value = null;
                this.previewImage = '';
                this.updateHandler();
                document.getElementById("closeAdd").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteCategory: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.service.category.requestURL + '/' + id,
                        headers: authHeader()
                    };
                    this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.isLoading = false;
                        this.updateHandler();
                    }).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
        setId: function (index) {
            this.singleCategory = this.categoryList[index];
        },
        updateCategory: function () {
            this.isLoading = true;
            let formData = new FormData();
            formData.append('name', this.singleCategory.name);
            formData.append('image', this.updatePhoto ? this.updatePhoto : this.singleCategory.image);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.service.category.requestURL + '/' + this.singleCategory.id,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.isLoading = false;
                this.previewImage = "";
                this.updatePhoto = "";
                this.$refs.updateImage.value = null;
                this.updateHandler();
                document.getElementById("closeUpdate").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}
