import { authHeader } from "../../../../auth";
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            setURL: this.$serverURL + this.$api.componentCMS.professionalSubService,
            pageCMSData: [],
            image: "",
            previewImageOne: "",
            titleColor: "",
            dataLoaded: false,
            colors: [
                 "#E91E63" ,"#F44336", "#9C27B0" , "#673AB7" , "#3F51B5" , "#2196F3" , "#03A9F4" , "#00BCD4" , "#009688" , "#4CAF50"
                                                      , "#8BC34A" , "#CDDC39" , "#FFEB3B" , "#FFC107" , "#FF9800" , "#795548" ]
        }
    },
    async created() {
        document.title = "Cosmo -  Profession Sub Services";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    headerFontColor: this.pageCMSData.headerFontColor ? this.pageCMSData.headerFontColor : '#FFFFFF',
                    pageBackgroundColor: this.pageCMSData.pageBackgroundColor ? this.pageCMSData.pageBackgroundColor : '#DADAD2',
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: authHeader(),
                };
                this.isLoading = true;
                this.dataLoaded = false;
                await this.createUpdateCMS(config);
                this.isLoading = false;
                await this.getCmsData(this.setURL);
                if (this.pageCMS) {
                    this.pageCMSData = this.pageCMS;
                }
                else {
                    this.pageCMSData = [];
                }
                this.dataLoaded = true;
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}