<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <div>
          <h3 class="fw-600 mb-0">Footer CMS</h3>
        </div>
      </div>
      <div class="mt-4">
        <form>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-xl-6">
              <label class="form-label text-blueish">Title:</label>
              <input type="text" v-model="title" class="form-control" placeholder="Enter title" />
            </div>

            <div class="col-12 col-sm-6 col-xl-6">
              <label class="form-label text-blueish">Email:</label>
              <input type="text" v-model="email" class="form-control" placeholder="Enter email" />
            </div>
            <div class="col-12 col-sm-6 col-xl-6">
              <label class="form-label text-blueish">Email Placeholder Text:</label>
              <input
                type="text"
                v-model="emailPlaceholderText"
                class="form-control"
                placeholder="Enter email placeholder text"
              />
            </div>
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-xl-6">
              <label class="form-label text-blueish">Copyright Text:</label>
              <ckeditor :editor="editor" v-model="copyrightText" :config="editorConfig"></ckeditor>
              <!-- <input
                type="text"
                v-model="copyrightText"
                class="form-control"
                placeholder="Enter footer info"
              />-->
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-6">
            <label class="form-label text-blueish">Description:</label>
            <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
            <!-- <textarea
                type="text"
                v-model="description"
                class="form-control"
                placeholder="Enter description"
                rows="3"
            ></textarea>-->
          </div>
          <div class="mt-5">
            <button
              type="button"
              @click="update()"
              class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3"
            >Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script src="../js/footer-cms-two.js"></script>

<style>
@import "../css/common.css";
</style>
  
