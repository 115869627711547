import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: "Admin",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            page: 1,
            limit: 10,
            totalPage: 0
        }
    },
    async created() {
        document.title = "Cosmo Salon - Admin";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.user.adminListURL + params,
            headers: authHeader()
        };
        await this.fetchData(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.adminListURL + params,
                headers: authHeader()
            };
            await this.fetchData(config);
        },
        fetchData: async function(config) {
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data.data.data; 
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteEntity: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "DELETE",
                            url: this.$serverURL + this.$api.user.deleteURL + '/' + id,
                            headers: authHeader()
                        };
                        this.$axios(config).then((response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.updateHandler();
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        }
    }
}