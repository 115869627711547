import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "HeaderCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            appText: null,
            appLink: null,
            headerText: null
        }
    },
    async created() {
        document.title = "Cosmo - Header CMS";
        await this.getHeaderCMS();
    },
    methods: {
        getHeaderCMS: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.headerCMS.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.appText = this.dataList.appText;
                        this.appLink = this.dataList.appLink;
                        this.headerText = this.dataList.headerText;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        checkValidation: function() {
            if(!this.headerText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter header text!"
                })
                return false;
            }
            if(!this.appText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter app text!"
                })
                return false;
            }
            if(!this.appLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter app link!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let data = {
                    appText: this.appText,
                    appLink: this.appLink,
                    headerText: this.headerText
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.headerCMS.requestURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                    }
                    this.isLoading = false;
                    this.getHeaderCMS();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}