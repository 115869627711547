import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';
import Toaster from '@meforma/vue-toaster';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import VueGoogleCharts from "vue3-googl-chart";
import vue3StarRatings from "vue3-star-ratings";
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import mixins from './mixin';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App);
app.component('Multiselect', Multiselect);
app.component("v-select", vSelect);
app.component('Datepicker', Datepicker);
app.component('QuillEditor', QuillEditor)
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$active = false;
app.config.globalProperties.$tokenKey = 'token';
app.config.globalProperties.$api = require('../config/api.json');
app.config.globalProperties.$filters = {
    formatDateTime(date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm:ss A');
    },
    formatDate(date) {
        return moment(String(date)).format('DD/MM/YYYY');
    }
};
const server = require('../config/server.json');



if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$hostURL = server.serverLocal.HOST;
    app.config.globalProperties.$serverURL = server.serverLocal.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.serverLocal.IMAGE_PATH;

} else {

    app.config.globalProperties.$hostURL = server.server.HOST;
    app.config.globalProperties.$serverURL = server.server.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.server.IMAGE_PATH;
}


app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCQuFaWDHVt8s9KwasbHJl7epPt_MEygvE',
        libraries: "places"
    }
});

app.use(CKEditor);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(Toaster, {
    position: 'top',
});
app.use(VCalendar, {})
app.component("vue3-star-ratings", vue3StarRatings);
app.use(VueGoogleCharts);
app.mixin(mixins);
app.use(router).mount('#app');
