import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            id: this.$route.params.id,
            expertData: {},
            imageFile: null,
            previewImage: null
        }
    },
    async created() {
        await this.getExpertData();
    },
    methods: {
        getExpertData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cosmoExpert.listURL + '/' + this.id,
                headers: authHeader()
            }
            await this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.expertData = response.data.data[0];
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("name", this.expertData.name);
            formData.append("specialIn", this.expertData.specialIn);
            formData.append("link", this.expertData.link);
            formData.append("image", this.imageFile ? this.imageFile : this.expertData.image);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cosmoExpert.listURL + '/' + this.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', this.$swal.stopTimer)
                          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.previewImage = "";
                    this.$refs.image.value = null;
                }
                this.isLoading = false;
                this.$router.push("/cosmo-expert");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}