<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <!-- <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li> -->
                            <!-- <li class="breadcrumb-item active" aria-current="page">All Page Title CMS</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label">Home Page Title:</label>
                                            <input type="text" v-model="homePageTitle" class="form-control" placeholder="Home page title">
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">About Page Title:</label>
                                            <input type="text" v-model="aboutPageTitle" class="form-control" placeholder="About page title">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label">Login Page Title:</label>
                                            <input type="text" v-model="loginPageTitle" class="form-control" placeholder="Login page title">
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">Registration Page Title:</label>
                                            <input type="text" v-model="registrationPageTitle" class="form-control" placeholder="Registration page title">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label">Contact Us Page Title:</label>
                                            <input type="text" v-model="contactPageTitle" class="form-control" placeholder="Contact page title">
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">Location Page Title:</label>
                                            <input type="text" v-model="locationPageTitle" class="form-control" placeholder="Location page title">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label">Experts Page Title:</label>
                                            <input type="text" v-model="expertsPageTitle" class="form-control" placeholder="Experts page title">
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">Professional Services Page Title:</label>
                                            <input type="text" v-model="professionalServicesPageTitle" class="form-control" placeholder="Professional services page title">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label">Professional Sub Services Page Title:</label>
                                            <input type="text" v-model="professionalSubServicePageTitle" class="form-control" placeholder="Professional sub services page title">
                                        </div>
                                    </div>
                                    <div>
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/page-title-cms.js"></script>