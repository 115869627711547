import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";

export default {
    name: "SectionTwoCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            imagePath: this.$imageURL + 'gallery-photos/',
            photo: "",
            updatePhoto: "",
            previewImage: "",
            name: "",
            dataList: [],
            singleData: [],
            categoryList: [],
            category: "",
            updateCategory: "",
        }
    },
    async created() {
        document.title = "Cosmo Salon -Gallery Category";
        await this.getDataList();
        await this.getCategoryList();
    },
    methods: {
        uploadImage(event) {
            this.photo = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateImage(event) {
            this.updatePhoto = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getDataList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.gallery.galleryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        async getCategoryList() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.gallery.categoryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        addEntity: async function () {
            this.isLoading = true;
            if (!this.category) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select category!"
                });
                this.isLoading = false;
                return false;
            }
            else if (!this.photo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select an image!"
                });
                this.isLoading = false;
                return false;
            }
            let formData = new FormData();
            formData.append('photo', this.photo);
            formData.append('categoryId', this.category.id);
            formData.append('categoryName', this.category.name);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.gallery.galleryURL,
                data: formData,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$refs.addImage.value = "";
                    this.photo = "";
                    this.category = "";
                    this.previewImage = "";
                }
                this.isLoading = false;
                this.getDataList();
                document.getElementById("closeButton").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteEntity: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.gallery.galleryURL + '/' + id,
                        headers: authHeader()
                    };
                    this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.isLoading = false;
                        this.getDataList();
                    }).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
        setId: function (index) {
            this.singleData = this.dataList[index];
            console.log(this.singleData);
            this.updateCategory = { id: this.singleData.categoryId, name: this.singleData.categoryName }
        },
        updateEntity: function () {
            this.isLoading = true;
            if (!this.singleData.photo && !this.updatePhoto) {
                this.$swal.fire({
                    icon: "error",
                    text: "Select an image!"
                });
                this.isLoading = false;
                return false;
            } else if (!this.updateCategory) {
                this.$swal.fire({
                    icon: "error",
                    text: "Select category!"
                });
                return false;
            }
            let formData = new FormData();
            formData.append('photo', this.updatePhoto ? this.updatePhoto : this.singleData.photo);
            formData.append('categoryId', this.updateCategory.id);
            formData.append('categoryName', this.updateCategory.name);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.gallery.galleryURL + '/' + this.singleData.id,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$refs.updateImage.value = "";
                    this.updateCategory = "";
                    this.updatePhoto = "";
                    this.previewImage = "";
                }
                this.isLoading = false;
                this.getDataList();
                document.getElementById("buttonClose").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
