<template>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Orders List</h3>
                </div>
            </div>
            <div class="ms-auto">
                <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#filterModal">
                    <i class="bi bi-funnel text-white float-left"></i> Filter
                </button>
                <button type="button" class="btn btn-warning ms-2" @click="exportData">
                    <i class="bi bi-download"></i> Export
                </button>
            </div>
            <div class="card p-3 shadow border-0 round-17">
            <div class="table-container">
                <div class="table-section">
                    <div class="table-responsive">
                        <table class="custom_table custom_table_header_grayish">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Invoice Id</th>
                                    <th>Customer Name</th>
                                    <th>Created By</th>
                                    <th>Payment Status</th>
                                    <th>Payment Method</th>
                                    <th>Total Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Order Status</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="orderList.length > 0">
                                <tr v-for="(data, index) in orderList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.invoiceId }}</td>
                                    <td>{{ data.customerName }}</td>
                                    <td>{{ data.createdByName }}</td>
                                    <td>{{ data.paymentStatus }}</td>
                                    <td>{{ data.paymentMethodName }}</td>
                                    <td>{{ data.total }}</td>
                                    <td>{{ data.paymentAmount }}</td>
                                    <td>{{ data.status }}</td>
                                    <td> {{ $filters.formatDate(data['createdAt']) }} </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <button type="button" class="btn btn-outline-primary m-1" @click="$router.push('/order-details/' + data.invoiceId)">
                                                <i class="bi bi-eye-fill"></i>
                                            </button> <button title="delete" @click="deleteOrder(data.invoiceId)" class="btn btn-outline-danger m-1">
                            <i class="fas fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="11">
                                        <div class="alert alert-warning" role="alert" style="text-align: center">
                                            No data found
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
            <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
                    <v-pagination v-if="orderList.length > 0" v-model="page" @update:modelValue="updateHandler" :pages="totalPage" :range-size="1" active-color="#212529"></v-pagination>
                </nav>
        </div>
            <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="filterClose"></button>
                        </div>
                        <div class="modal-body">
                            <form class="filter_form" @submit.prevent="validateFilter">
                                <div class="row mb-3">
                                    <div class="col-6 col-sm-6 mb-1">
                                        <label class="form-label">Invoice:</label>
                                        <Multiselect v-model="filter.invoiceId" id="inputVendor" :options="invoiceList" :searchable="true"/>
                                    </div>
                                    <div class="col-6 col-sm-6 mb-1">
                                        <label class="form-label">Professional:</label>
                                        <Multiselect v-model="filter.professionalId" id="inputVendor" :options="professionalList" :searchable="true"/>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-6 col-sm-6 mb-1">
                                        <label class="form-label">Client:</label>
                                        <Multiselect v-model="filter.clientId" id="inputVendor" :options="clientList" :searchable="true"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Start Date:</label>
                                        <input v-model="filter.startDate" type="date" class="form-control"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">End Date:</label>
                                        <input v-model="filter.endDate" type="date" class="form-control"/>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="text-center">
                                        <button type="submit" @click="clearFilter()" class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal">
                                            Clear
                                        </button>
                                        <button type="submit" class="btn btn-sm btn-success rounded-pill ps-3 pe-3">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/order-list.js"></script>
  
<style scoped>
    @import "../css/order-list.css";
    @import url("../../../assets/css/pagination.css");
</style>
  