<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Section Four CMS</h3>
                </div>
            </div>
            <div class="mt-4">
                <form>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Title:</label>
                            <input type="text" v-model="title" class="form-control" placeholder="Enter title">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Button Text:</label>
                            <input type="text" v-model="buttonText" class="form-control" placeholder="Enter button text">
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <label class="form-label text-blueish">Button Link:</label>
                            <input type="text" v-model="buttonLink" class="form-control" placeholder="Enter button link">
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-6">
                            <label class="form-label text-blueish">Sub Title:</label>
                            <textarea v-model="subTitle" class="form-control text-site-gray-dark" rows="4" placeholder="Enter sub title"></textarea>
                        </div>
                    </div>
                    <!-- <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6">
                            <label for="image" class="add_product_file_upload">Upload Background Image</label>
                            <input type="file" id="image" ref="image" @change="onImageChange($event)" class="d-none" accept="image/*">
                        </div>
                        <div class="col-12 col-sm-6">
                            <label class="form-label text-blueish">UPLOADING : &nbsp;</label>
                            <img v-if="previewImage" :src="previewImage" height="100" width="150"/>
                            <img v-else-if="image !== 'null' && image" :src="$imageURL + 'section-six-cms/' + image" height="100" width="150"/>
                            <img v-else src="../../../assets/images/400.png" height="100" width="150"/>
                        </div>
                    </div> -->
                    <div class="mt-5">
                        <button type="button" @click="update()" class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/section-four-cms.js"></script>

<style>
    @import "../css/common.css";
</style>
  
