<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Edit Data</h3>
                </div>
            </div>
            <div class="mt-4">
                <form>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-4">
                            <label class="form-label text-blueish">Title:</label>
                            <input type="text" v-model="singleData.title" class="form-control" placeholder="Enter title">
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6 col-xl-4">
                            <label class="form-label text-blueish">Sub Title:</label>
                            <textarea v-model="singleData.subTitle" class="form-control text-site-gray-dark" rows="4" placeholder="Enter sub title"></textarea>
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6">
                            <label class="form-label text-blueish">Image: [Preferred Image Size: 512X630, Max Upload Limit: 1MB]</label>
                            <label for="file" class="add_product_file_upload">Upload Image</label>
                            <input type="file" id="file" ref="image" @change="onImageChange($event)" class="d-none">
                        </div>
                        <div class="col-12 col-sm-6">
                            <label class="form-label text-blueish">UPLOADING : &nbsp;</label>
                            <img v-if="previewImage" :src="previewImage" height="100" width="150"/>
                            <img v-else :src="$imageURL + 'section-three-cms/' + singleData.image" height="100" width="150"/>
                        </div>
                    </div>
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-sm-6">
                            <label for="logo" class="add_product_file_upload">Upload Logo</label>
                            <input type="file" id="logo" ref="logo" @change="onLogoChange($event)" class="d-none">
                        </div>
                        <div class="col-12 col-sm-6">
                            <label class="form-label text-blueish">UPLOADING : &nbsp;</label>
                            <img v-if="previewLogo" :src="previewLogo" height="100" width="150"/>
                            <img v-else :src="$imageURL + 'section-three-cms/' + singleData.logo" height="100" width="150"/>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button type="button" @click="update()" class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/edit-data.js"></script>

<style>
    @import "../css/add-review.css";
</style>
  
