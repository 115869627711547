import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "SectionFourCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            title: null,
            subTitle: null,
            buttonText: null,
            buttonLink: null,
            imageFile: "",
            image: "",
            previewImage: ""
        }
    },
    async created() {
        document.title = "Cosmo - Section Four CMS";
        await this.fetchData();
    },
    methods: {
        fetchData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.sectionFourCMS.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.title = this.dataList.title;
                        this.subTitle = this.dataList.subTitle;
                        this.buttonText = this.dataList.buttonText;
                        this.buttonLink = this.dataList.buttonLink;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            if(!this.buttonText) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button text!"
                })
                return false;
            }
            if(!this.buttonLink) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter button link!"
                })
                return false;
            }
            if(!this.subTitle) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter sub title!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let data = {
                    title: this.title,
                    subTitle: this.subTitle,
                    buttonText: this.buttonText,
                    buttonLink: this.buttonLink,
                    backgroundImage: this.imageFile ? this.imageFile : this.image
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.sectionFourCMS.requestURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                    }
                    this.isLoading = false;
                    this.fetchData();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}