<template>
    <div class="custom-container-fluid">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="m-auto">
                <h3 class="fw-600 logo_section_title">Profile</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9 m-auto">
                <div class="card overflow-hidden radius-10">
                    <div class="profile-cover bg-dark position-relative mb-4">
                        <div class="user-profile-avatar shadow position-absolute top-50 start-0 translate-middle-x mb-2">
                            <img src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg" alt="..."/>
                        </div>
                    </div>
                    <div class="card-body mt-3">
                        <div class="mt-5 d-flex flex-column flex-sm-row align-items-start justify-content-between gap-3 gap-sm-0">
                            <div class="mt-2">
                                <h3 class="mb-2">
                                    {{ userDetails.fullName }}
                                </h3>
                                <p class="mb-1"><strong>Email:</strong>
                                    {{ email }}
                                </p>
                            </div>
                            <div>
                                <a href="javascript:;" class="btn btn-primary" style="background-color: #610000; border-color: #610000; !important" data-bs-toggle="modal" data-bs-target="#change_password">
                                    <i class="bx bxs-key"></i>Change Password
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card radius-10 mt-3">
                    <div class="card-body">
                        <form>
                            <h5 class="mb-3">Edit Profile</h5>
                            <h5 class="mb-0 mt-4">User Information</h5>
                            <hr />
                            <div class="row g-3">
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">Full Name</label>
                                    <input type="text" class="form-control" v-model="userDetails.fullName" placeholder="N/A"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">Email</label>
                                    <input type="email" class="form-control" v-model="userDetails.email" disabled placeholder="N/A"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">Phone Number</label>
                                    <input type="text" class="form-control" v-model="userDetails.phoneNo" placeholder="N/A"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">Full Address</label>
                                    <input type="text" class="form-control" v-model="userDetails.streetAddress" placeholder="N/A"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">City</label>
                                    <input type="text" class="form-control" v-model="userDetails.city" placeholder="N/A"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label class="form-label">Postal Code</label>
                                    <input type="text" class="form-control" v-model="userDetails.zipCode" placeholder="N/A"/>
                                </div>
                            </div>
                            <div class="text-start mt-3">
                                <button @click="save" type="button" class="btn px-4 btn-primary" style="background-color: #610000; border-color: #610000; !important">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ChangePassword/>
</template>
  
<script src="../js/profile.js"></script>

<style scoped>
    @import "../css/profile.css";
</style>