import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "ServiceCategory",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            name: "",
            categoryList: [],
            subCategoryList: [],
            category: "",
            updateParentCategory: "",
            singleSubCategory: {},
            imagePath: this.$imageURL + 'service-sub-category/',
            page: 1,
            limit: 10,
            totalPage: 0,
            previewImage: '',
            image: '',
            updatePhoto: '',
        }
    },
    async created() {
        document.title = "Cosmo Salon - Service Sub Category";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.service.subCategoryURL + params,
            headers: authHeader()
        };
        await this.getSubCategoryList(config);
        await this.getCategoryList();
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.subCategoryURL + params,
                headers: authHeader()
            };
            await this.getSubCategoryList(config);
        },
        uploadImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateImage(event) {
            this.updatePhoto = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getSubCategoryList: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.subCategoryList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getCategoryList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.category.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data.data.map(el => {
                        return { categoryId: el.id, categoryName: el.name }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        addSubCategory: async function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert sub category name!"
                });
                return false;
            } else if (!this.category) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select category!"
                });
                return false;
            } else if (!this.image) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload image!"
                });
                return false;
            }
            let formData = new FormData();
            formData.append('categoryId', this.category.categoryId);
            formData.append('categoryName', this.category.categoryName);
            formData.append('subCategoryName', this.name);
            formData.append('subCategoryImage', this.image);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.service.subCategoryURL,
                data: formData,
                headers: authHeader()
            };
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.name = "";
                    this.category = "";
                    this.$refs.addImage.value = null;
                    this.previewImage = '';
                    this.updateHandler();
                    document.getElementById("closeAdd").click();
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        setSingleItem: function (data) {
            this.singleSubCategory = data;
            this.updateParentCategory = { categoryId: data.categoryId, categoryName: data.categoryName }
        },
        updateCategory: function () {
            if (!this.singleSubCategory.subCategoryName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert sub category name!"
                });
                return false;
            } else if (!this.updateParentCategory) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select category!"
                });
                return false;
            }
            let formData = new FormData();
            formData.append('categoryId', this.updateParentCategory.categoryId);
            formData.append('categoryName', this.updateParentCategory.categoryName);
            formData.append('subCategoryName', this.singleSubCategory.subCategoryName);
            formData.append('subCategoryImage', this.updatePhoto ? this.updatePhoto : this.singleSubCategory.subCategoryImage);
            this.isLoading = true;
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.service.subCategoryURL + '/' + this.singleSubCategory.id,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleSubCategory = {};
                this.updateParentCategory = "";
                this.previewImage = "";
                this.updatePhoto = "";
                this.$refs.updateImage.value = null;
                this.updateHandler();
                document.getElementById("closeUpdate").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteSubCategory: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.service.subCategoryURL + '/' + id,
                        headers: authHeader()
                    };
                    this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.isLoading = false;
                        this.updateHandler();
                    }).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
    }
}
