import { authHeader } from "../../../../auth";
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            setURL: this.$serverURL + this.$api.componentCMS.home,
            pageCMSData: [],
            image: "",
            previewImageOne: "",
            titleColor: "",
            dataLoaded: false,
            colors: [
                 "#E91E63" ,"#F44336", "#9C27B0" , "#673AB7" , "#3F51B5" , "#2196F3" , "#03A9F4" , "#00BCD4" , "#009688" , "#4CAF50"
                                                      , "#8BC34A" , "#CDDC39" , "#FFEB3B" , "#FFC107" , "#FF9800" , "#795548" ]
        }
    },
    async created() {
        document.title = "Cosmo -  Home";
        
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    sectionOneTitleFontColor: this.pageCMSData.sectionOneTitleFontColor ? this.pageCMSData.sectionOneTitleFontColor : '#3E3E3E',
                    sectionOneSubTitleFontColor: this.pageCMSData.sectionOneSubTitleFontColor ? this.pageCMSData.sectionOneSubTitleFontColor : '#3E3E3E',
                    sectionTwoHeaderFontColor: this.pageCMSData.sectionTwoHeaderFontColor ? this.pageCMSData.sectionTwoHeaderFontColor : '#3E3E3E',
                    sectionTwoTitleFontColor: this.pageCMSData.sectionTwoTitleFontColor ? this.pageCMSData.sectionTwoTitleFontColor : '#3E3E3E',
                    sectionTwoSubTitleFontColor: this.pageCMSData.sectionTwoSubTitleFontColor ? this.pageCMSData.sectionTwoSubTitleFontColor : '#3E3E3E',
                    sectionThreeHeaderFontColor: this.pageCMSData.sectionThreeHeaderFontColor ? this.pageCMSData.sectionThreeHeaderFontColor : '#3E3E3E',
                    sectionThreeTitleFontColor: this.pageCMSData.sectionThreeTitleFontColor ? this.pageCMSData.sectionThreeTitleFontColor : '#3E3E3E',
                    sectionThreeSubTitleFontColor: this.pageCMSData.sectionThreeSubTitleFontColor ? this.pageCMSData.sectionThreeSubTitleFontColor : '#3E3E3E',
                    sectionFourTitleFontColor: this.pageCMSData.sectionFourTitleFontColor ? this.pageCMSData.sectionFourTitleFontColor : '#3E3E3E',
                    sectionFourSubTitleFontColor: this.pageCMSData.sectionFourSubTitleFontColor ? this.pageCMSData.sectionFourSubTitleFontColor : '#3E3E3E',
                    sectionFiveHeaderFontColor: this.pageCMSData.sectionFiveHeaderFontColor ? this.pageCMSData.sectionFiveHeaderFontColor : '#3E3E3E',
                    sectionFiveTitleFontColor: this.pageCMSData.sectionFiveTitleFontColor ? this.pageCMSData.sectionFiveTitleFontColor : '#3E3E3E',
                    sectionFiveSubTitleFontColor: this.pageCMSData.sectionFiveSubTitleFontColor ? this.pageCMSData.sectionFiveSubTitleFontColor : '#3E3E3E',
                    sectionSixTitleFontColor: this.pageCMSData.sectionSixTitleFontColor ? this.pageCMSData.sectionSixTitleFontColor : '#3E3E3E',
                    sectionSixSubTitleFontColor: this.pageCMSData.sectionSixSubTitleFontColor ? this.pageCMSData.sectionSixSubTitleFontColor : '#3E3E3E',
                    sectionSevenSubTitleFontColor: this.pageCMSData.sectionSevenSubTitleFontColor ? this.pageCMSData.sectionSevenSubTitleFontColor : '#3E3E3E',
                    sectionSevenHeaderFontColor: this.pageCMSData.sectionSevenHeaderFontColor ? this.pageCMSData.sectionSevenHeaderFontColor : '#3E3E3E',
                    sectionSevenTitleFontColor: this.pageCMSData.sectionSevenTitleFontColor ? this.pageCMSData.sectionSevenTitleFontColor : '#3E3E3E',


                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: authHeader(),
                };
                this.isLoading = true;
                this.dataLoaded = false;
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
                this.isLoading = false;
                if (this.pageCMS) {
                    this.pageCMSData = this.pageCMS;
                }
                else {
                    this.pageCMSData = [];
                }
                this.dataLoaded = true;
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}