<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <p class="mb-1">Hi {{ name }}</p>
                    <h3>Welcome to Cosmo Salon!</h3>
                </div>
            </div>
            <div class="row gy-4">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div class="dashboard_card shadow-sm">
                        <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                            <div class="">
                              <i class="fas fa-user-tie fa-2x text-site-maroon"></i>
                            </div>
                            <div class="w-100">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Today Total Professional</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">#{{dashboardData.todayTotalProfessional}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div class="dashboard_card shadow-sm">
                        <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                            <div class="">
                              <i class="fas fa-user-tie fa-2x text-site-maroon"></i>
                            </div>
                            <div class="w-100">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Professional</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">#{{dashboardData.totalProfessional}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div class="dashboard_card shadow-sm">
                        <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                            <div class="">
                                <img src="../../../assets/images/user.svg">
                            </div>
                            <div class="w-100">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Today Total Client</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">#{{dashboardData.todayTotalClient}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div class="dashboard_card shadow-sm">
                        <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                            <div class="">
                                <img src="../../../assets/images/user.svg">
                            </div>
                            <div class="w-100">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Client</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">#{{dashboardData.totalClient}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-4 mt-4">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div class="dashboard_chart_card shadow-sm">
                        <GChart type="PieChart" :options="optionsServices" :data="topServices"/>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div class="dashboard_chart_card shadow-sm">
                        <GChart type="PieChart" :options="optionsProducts" :data="topProducts"/>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div class="dashboard_card shadow-sm">
                        <div class="d-flex gap-2 align-items-center">
                            <div>
                                <img src="../../../assets/images/berchart.svg">
                            </div>
                            <div class="w-100 align-items-center">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Invoice Price</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">${{dashboardData.totalInvoiceValue}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard_card shadow-sm"  style="margin-top: 20px">
                        <div class="d-flex gap-2 align-items-center">
                            <div>
                                <img src="../../../assets/images/berchart.svg">
                            </div>
                            <div class="w-100 align-items-center">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Today Total Invoice Price</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">${{dashboardData.todayTotalInvoiceValue}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard_card shadow-sm" style="margin-top: 20px">
                        <div class="d-flex gap-2 align-items-center">
                            <div>
                                <img src="../../../assets/images/berchart.svg">
                            </div>
                            <div class="w-100 align-items-center">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Ordered</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">#{{dashboardData.totalInvoice}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard_card shadow-sm" style="margin-top: 20px">
                        <div class="d-flex gap-2 align-items-center">
                            <div>
                                <img src="../../../assets/images/berchart.svg">
                            </div>
                            <div class="w-100 align-items-center">
                                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Today Total Ordered</p>
                                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">#{{dashboardData.todayTotalInvoice}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/home.js"></script>

<style scoped>
    @import "../css/home.css";
</style>


