<template>
  <div>
    <div class="sidebar" :class="[store.active ? 'close' : '']">
      <div class="logo_section d-flex justify-content-center align-items-center position-relative ps-3 pe-3">
        <a class="sidebar_toggle_btn" @click="store.active =! store.active"
          :class="[store.active ? 'sidebar_active_toggle' : '']">
          <i class="bi bi-arrow-right"></i>
        </a>
        <h4 class="text-center">
          <span class="logo_section_title">COSMO</span>
          <span class="d-block logo_section_title_sub">ADMIN DASHBOARD</span>
        </h4>
      </div>
      <ul class="list-unstyled sidebar_body">
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/dashboard" class="sidebar_link">
                <span class="bi bi-speedometer sidebar_link_icon"></span>
                <span class="sidebar_link_text">Dashboard</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                data-bs-target="#professional-collapse" aria-expanded="false">
                <span class="bi bi-person-bounding-box sidebar_link_icon"></span>
                <span class="sidebar_link_text">Professional</span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="professional-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/unapproved-user" class="sidebar_link">
                            <span class="bi bi-person-x sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Unapproved</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="professional-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/approved-user" class="sidebar_link">
                            <span class="bi bi-person-check sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Approved</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="professional-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/rejected-user" class="sidebar_link">
                            <span class="bi bi-person-x sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Rejected</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>
        <li class="mb-1 sidebar_list">
            <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                data-bs-target="#user-collapse" aria-expanded="false">
                <span class="bi bi-people-fill sidebar_link_icon"></span>
                <span class="sidebar_link_text">User</span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="user-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/admin" class="sidebar_link">
                            <span class="bi bi-person-circle sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Admin</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="user-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/client" class="sidebar_link">
                            <span class="bi bi-person sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Client</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>
        
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/orders" class="sidebar_link">
                <span class="bi bi-cart3 sidebar_link_icon"></span>
                <span class="sidebar_link_text">Orders</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/payment-config" class="sidebar_link">
                <span class="bi bi-wallet sidebar_link_icon"></span>
                <span class="sidebar_link_text">Payment API Credential</span>
            </router-link>
        </li>
        <!-- <li class="mb-1 sidebar_list">
            <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                data-bs-target="#platform-collapse" aria-expanded="false">
                <span class="bi bi-coin sidebar_link_icon"></span>
                <span class="sidebar_link_text">Platform Cost</span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="platform-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/global-platform-cost" class="sidebar_link">
                            <span class="bi bi-globe sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Global</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="platform-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/custom-platform-fee" class="sidebar_link">
                            <span class="bi bi-badge-cc sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Custom</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="platform-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/platform-fee-list" class="sidebar_link">
                            <span class="bi bi-cash sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Fee List</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li> -->
        
        <!-- <li class="mb-1 sidebar_list">
            <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                data-bs-target="#gallery-collapse" aria-expanded="false">
                <span class="bi bi-images sidebar_link_icon"></span>
                <span class="sidebar_link_text">Gallery</span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="gallery-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/gallery-category" class="sidebar_link">
                            <span class="bi bi-grid sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Category</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="gallery-collapse">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/gallery" class="sidebar_link">
                            <span class="bi bi-image-fill sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Gallery</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li> -->
        
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/customer-review" class="sidebar_link">
                <span class="bi bi-pencil-square sidebar_link_icon"></span>
                <span class="sidebar_link_text">Customer Review</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/studio" class="sidebar_link">
                <span class="bi bi-pin-map sidebar_link_icon"></span>
                <span class="sidebar_link_text">Studio</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/site-social-links" class="sidebar_link">
                <span class="bi bi-hdd-stack sidebar_link_icon"></span>
                <span class="sidebar_link_text">Site Social Links</span>
            </router-link>
        </li>
        <!-- <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/cosmo-expert" class="sidebar_link">
                <span class="bi bi-person-video2 sidebar_link_icon"></span>
                <span class="sidebar_link_text">Cosmo Expert</span>
            </router-link>
        </li> -->
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/contact-list" class="sidebar_link">
                <span class="bi bi-list-ol sidebar_link_icon"></span>
                <span class="sidebar_link_text">Contact List</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/service-category" class="sidebar_link">
                <span class="bi bi-receipt-cutoff sidebar_link_icon"></span>
                <span class="sidebar_link_text">Service Category</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/service-sub-category" class="sidebar_link">
                <span class="bi bi-receipt-cutoff sidebar_link_icon"></span>
                <span class="sidebar_link_text">Service Sub Category</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/service-color" class="sidebar_link">
                <span class="bi bi-receipt-cutoff sidebar_link_icon"></span>
                <span class="sidebar_link_text">Service Color</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <router-link tag="a" to="/profile" class="sidebar_link">
                <span class="bi bi-person-workspace sidebar_link_icon"></span>
                <span class="sidebar_link_text">Profile</span>
            </router-link>
        </li>
        <li class="mb-1 sidebar_list">
            <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                data-bs-target="#settings-collapse1" aria-expanded="false">
                <span class="bi bi-gear sidebar_link_icon"></span>
                <span class="sidebar_link_text">CMS</span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/header-cms" class="sidebar_link">
                            <span class="bi bi-card-heading sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Header</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/footer-tow-cms" class="sidebar_link">
                            <span class="bi bi-card-heading sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Footer</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/section-one-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section One</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/section-two-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section Two</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/section-three-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section Three</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/section-four-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section Four</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/section-five-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section Five</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/section-six-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section Six</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/footer-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Section Seven</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/site-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Site CMS</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/about-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">About CMS</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/cosmo-expert-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Cosmo Expert</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/contact-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Contact CMS</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/page-title-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Page Title CMS</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/client-terms-condition" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Client Terms & Condition</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/professional-terms-condition" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Professional Terms & Condition</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>
        <li class="mb-1 sidebar_list">
            <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                data-bs-target="#settings-collapse2" aria-expanded="false">
                <span class="bi bi-gear sidebar_link_icon"></span>
                <span class="sidebar_link_text">Component UI</span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/home-component-cms" class="sidebar_link">
                            <span class="bi bi-card-heading sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Home</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/contact-component-cms" class="sidebar_link">
                            <span class="bi bi-card-heading sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Contact</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/professional-service-component-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Professional Service</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/professional-sub-service-component-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Professional Sub Service</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/location-component-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Location</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/cosmo-professional-component-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">Cosmo Professional</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="collapse sidebar_submenu mt-1" id="settings-collapse2">
                <ul class="list-unstyled">
                    <li class="mb-1 sidebar_list">
                        <router-link tag="a" to="/about-component-cms" class="sidebar_link">
                            <span class="bi bi-intersect sidebar_link_icon"></span>
                            <span class="sidebar_link_text">About</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>
      </ul>
        <div class="sidebar_footer d-flex align-items-center justify-content-start">
            <a class="btn border-0 d-flex gap-4 align-items-center fs-16 ff-poppins400 fw-600 text-site-dark" @click="logout">
                <span class="material-icons-outlined">login</span>Log Out
            </a>
        </div>
    </div>
    <div id="side-drawer-void" class="position-fixed" @click="store.active = false"></div>
  </div>
</template>

<script src="../js/sidebar.js"></script>

<style scoped>
  @import "../css/sidebar.css";
</style>