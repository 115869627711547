import { authHeader } from './auth';
export default {
    data() {
        return {
            pageCMS: []
        }
    },
    methods: {
        getCmsData: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.pageCMS = await response.data.data[0];
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        createUpdateCMS: async function (config) {
            try {
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200 || response.data.statusCode == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
    }
}