<template>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid">
        <div class="card border-0 px-5 py-5 rounded-0 shadow-sm" id="doc">
            <h1 class="fst-italic ff-poppins700">Cosmo <span class="text-site-maroon">Salon</span></h1>
            <div class="row g-3">
                <div class="col-12 col-sm-6">
                    <p class="ff-poppins400 fs-20">{{ professionalDetails.businessName }}</p>
                    <p class="mb-0 fs-15">
                        {{ professionalDetails.streetAddress }} <br>
                        {{ professionalDetails.city }} <br>
                        {{ professionalDetails.country }} <br>
                        {{ professionalDetails.phoneNo }} <br>
                    </p>
                    <p class="fs-15">{{ professionalDetails.email }}</p>
                </div>
                <div class="col-12 col-sm-6">
                    <p class="ff-poppins400 fs-20">Invoice</p>
                    <table border="0">
                        <tr>
                            <td style="color: #8dabc4">Invoice:</td>
                            <td>#{{ orderDetails.invoiceId }}</td>
                        </tr>
                        <tr>
                            <td style="min-width: 150px; color: #8dabc4">Billed On:</td>
                            <td>{{ $filters.formatDate(orderDetails.createdAt) }}</td>
                        </tr>
                        <tr>
                            <td style="color: #8dabc4">Payment Status:</td>
                            <td>{{ orderDetails.paymentStatus }}</td>
                        </tr>
                        <tr>
                            <td style="color: #8dabc4">Invoice Status:</td>
                            <td>{{ orderDetails.status }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row g-3">
                <div class="col-12 col-sm-6">
                    <p class="ff-poppins400 fs-20">Bill To</p>
                    <p class="ff-poppins500 fs-20 mb-2">{{ billingAddress.name }}</p>
                    <p class="mb-0 ff-poppins500 fs-16">
                        {{ billingAddress.addressOne }}<br/>
                        {{ billingAddress.city }}, {{ billingAddress.stateCode }}
                        {{ billingAddress.postCode }}<br/>
                        {{ billingAddress.country }}
                    </p>
                </div>
                <div class="col-12 col-sm-6" v-if="orderDetails.paymentStatus != 'Pending'">
                    <div class="card border-2 rounded-0">
                        <div class="d-flex">
                            <span class="bg-site-maroon px-5 py-2 text-center fs-20 fs-sm-18 text-white">
                                {{ orderDetails.paymentStatus == "Paid" ? "PAID" : orderDetails.paymentStatus == "Partial Paid" ? "PARTIAL PAID" : "PENDING" }}
                            </span>
                            <span class="w-100 p-2 text-end fs-20 fs-sm-18" style="background: #e8e8e9; border: 1.5px solid #bdbdbd">on {{ $filters.formatDate(orderDetails.createdAt) }}</span>
                        </div>
                        <div class="card-body">
                            <h2 class="mb-0 text-center">
                                ${{ orderDetails.paymentAmount ? orderDetails.paymentAmount : 0 }}
                                <span style="font-weight: 400; font-size: 20px; line-height: 30px; color: #a2a2a2;">USD</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 table-responsive">
                <table border="0" class="w-100">
                    <thead style="background: #e8e8e9; border: 2px solid #bdbdbd; padding: 5px 10px;">
                        <tr>
                            <th class="py-2 px-3">Date</th>
                            <th style="min-width: 120px">Name</th>
                            <th style="min-width: 50px">Qty</th>
                            <th>Price</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="border: 2px solid #bdbdbd" v-for="(data, index) in itemList" :key="index">
                            <td class="py-2 px-3">
                                {{ $filters.formatDate(orderDetails.createdAt) }}
                            </td>
                            <td>{{ data.name }}</td>
                            <td>{{ data.quantity ? data.quantity : 1 }}</td>
                            <td>$ {{ data.itemPrice }}</td>
                            <td>$ {{ data.itemPrice ? data.itemPrice * (data.quantity ? data.quantity : 1) : 0 }}</td>
                        </tr>
                        <tr>
                            <td colspan="3"></td>
                            <td colspan="2">
                                <div class="d-flex gap-4">
                                    <span style="min-width: 150px">Subtotal</span>
                                    <span>$ {{ orderDetails.subTotal }}</span>
                                </div>
                                <div class="d-flex gap-4" v-if="orderDetails.discountAmount">
                                    <span style="min-width: 150px">Discount</span>
                                    <span>$ {{ orderDetails.discountAmount }}</span>
                                </div>
                                <div class="d-flex gap-4">
                                    <span style="min-width: 150px">Total</span>
                                    <span>$ {{ orderDetails.total }}</span>
                                </div>
                                <div class="d-flex gap-4">
                                    <span style="min-width: 150px">Paid</span>
                                    <span>$ {{ orderDetails.paymentAmount ? orderDetails.paymentAmount : 0 }}</span>
                                </div>
                                <hr class="my-2" style="border-top: 2px solid #bdbdbd !important"/>
                                <div class="d-flex gap-4">
                                    <span class="ff-poppins500" style="min-width: 150px">Amount Due</span>
                                    <span>
                                        $&nbsp;{{ orderDetails.paymentAmount ? parseFloat(orderDetails.total) - parseFloat( orderDetails.paymentAmount ? orderDetails.paymentAmount : 0) : parseFloat(orderDetails.total)}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>  
        <div class="row gutters float-end mt-3">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="custom-actions-btns mb-5">
                    <a href="javascript:void(0)" class="btn btn-warning m-1" @click="generatePDF">
                        <i class="bi bi-printer"></i> Print
                    </a>
                </div>
            </div>
        </div> 
    </div>
</template>
<script src="../js/order-details.js"></script>
