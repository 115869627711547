import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            id: this.$route.params.id,
            studioData: {},
            previewImage: null,
            imagePath: this.$imageURL + 'studio/',
            updatePhoto: "",
            addressLocation: "",
            lat: "",
            lng: "",
            center: { lat: 0, lng: 0 },
            markers: [
                {
                    position: {
                        lat: 0, lng: 0
                    }
                }
            ]
        }
    },
    async created() {
        document.title = "Cosmo Salon - Studio";
        await this.getStudioData();
    },
    methods: {
        updateImage(event) {
            this.updatePhoto = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getStudioData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.studio.requestURL + '/' + this.id,
                headers: authHeader()
            }
            await this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.studioData = response.data.data[0];
                    this.addressLocation = this.studioData.address;
                    this.center.lat = this.studioData?.lat ? parseFloat(this.studioData.lat) : 41.70753939999999;
                    this.center.lng = this.studioData?.lng ? parseFloat(this.studioData.lng) : -86.8950297;
                    this.markers[0].position.lat = parseFloat(this.studioData.lat);
                    this.markers[0].position.lng = parseFloat(this.studioData.lng);
                    this.isLoading = false;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        handleAddressLatLng(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.lat = locationData.geometry.location.lat();
            this.lng = locationData.geometry.location.lng()
            this.center = { lat: this.lat, lng: this.lng },
                this.markers = [{
                    position: {
                        lat: this.lat, lng: this.lng
                    }
                }]
            this.addressLocation = location;
        },
        updateEntity: function () {
            this.isLoading = true;
            if (!this.studioData.studioName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter studio name!"
                });
                this.isLoading = false;
                return false;
            }
            else if (!this.studioData.locationName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter location name!"
                });
                return false;
            }
            else if (!this.studioData.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
                return false;
            }
            else if (!this.studioData.contactNo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact number!"
                });
                return false;
            }
            else if (!this.studioData.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            let formData = new FormData();
            formData.append('image', this.updatePhoto ? this.updatePhoto : this.studioData.image);
            formData.append('studioName', this.studioData.studioName);
            formData.append('locationName', this.studioData.locationName);
            formData.append('email', this.studioData.email);
            formData.append('contactNo', this.studioData.contactNo);
            formData.append('description', this.studioData.description);
            formData.append('mapLink', this.studioData.mapLink);
            formData.append('lat', this.lat ? this.lat : this.studioData.lat);
            formData.append('lng', this.lng ? this.lng : this.studioData.lng);
            formData.append('address', this.addressLocation);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.studio.requestURL + '/' + this.studioData.id,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$refs.updateImage.value = "";
                    this.updatePhoto = "";
                    this.previewImage = "";
                    this.isLoading = false;
                    this.$router.push("/studio");
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}