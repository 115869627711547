import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";

export default {
    name: "AboutCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            pageTitle: null,
            pageSubTitle: null,
            pageDescription: null,
            previewImage: null,
            image: null,
            imageFile: null
        }
    },
    async created() {
        document.title = "Cosmo Salon - About CMS";
        await this.fetchData();
    },
    methods: {
        fetchData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.aboutCMS.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                console.log(response.data.data.data[0])
                if (response.data.statusCode == 200) {
                    if (!response.data.data.data[0]) {
                        this.dataList = [];
                    }
                    else {

                        this.dataList = response.data.data.data[0];
                        this.pageTitle = this.dataList.pageTitle;
                        this.pageSubTitle = this.dataList.pageSubTitle;
                        this.pageDescription = this.dataList.pageDescription;
                        this.pageImage = this.dataList.pageImage;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function () {
            if (!this.pageTitle) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            if (!this.pageSubTitle) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter sub title!"
                })
                return false;
            }
            if (!this.pageDescription) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter description!"
                })
                return false;
            }
            return true;
        },
        update: async function () {
            if (this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("pageTitle", this.pageTitle);
                formData.append("pageSubTitle", this.pageSubTitle);
                formData.append("pageDescription", this.pageDescription);
                formData.append("pageImage", this.imageFile ? this.imageFile : this.pageImage);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.aboutCMS.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                        this.$refs.image.value = null;
                    }
                    this.isLoading = false;
                    this.fetchData();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}