import Loading from "vue-loading-overlay";
import {
    authHeader
} from "../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: "UnapprovedUserList",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            page: 1,
            limit: 10,
            totalPage: 0,
            userId: ""
        }
    },
    async created() {
        document.title = "Cosmo Salon - Unapproved User";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.user.unapprovedUserURL + params,
            headers: authHeader()
        };
        await this.fetchData(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.unapprovedUserURL + params,
                headers: authHeader()
            };
            await this.fetchData(config);
        },
        fetchData: async function (config) {
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        setId: function (id) {
            this.userId = id;
        },
        approveUser: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "This user will be approved!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Approve'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    let config = {
                        method: "PATCH",
                        url: this.$serverURL + this.$api.user.doApproveURL + '/' + id,
                        headers: authHeader()
                    };
                    this.$axios(config).then(async (response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: "User approved successfully.",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.isLoading = false;
                        await this.updateHandler();
                    }).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
        rejectUser: async function () {
            let data = {
                rejectionReason: this.rejectionReason
            }
            this.isLoading = true;
            let config = {
                method: "PATCH",
                data: data,
                url: this.$serverURL + this.$api.user.doRejectURL + this.userId,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: "User rejected successfully.",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    document.getElementById("closeAdd").click();
                }
                this.isLoading = false;
                await this.updateHandler();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}