import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";

export default {
    name: "Studio",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            image: "",
            previewImage: "",
            studioName: "",
            locationName: "",
            email: "",
            contactNo: "",
            description: "",
            mapLink: "",
            lat: "",
            lng: "",
            addressLocation: "",
            center: { lat: 43.41687425184668, lng: -84.827801875 },
            markers: [
                {
                    position: {
                        lat: 43.41687425184668, lng: -84.827801875
                    }
                }
            ],
        }
    },
    async created() {
        document.title = "Cosmo Salon - Studio";
    },
    methods: {
        setPlace(e) {
            this.lat = e.latLng.lat();
            this.lng = e.latLng.lng();
        },
        uploadImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        handleAddressLatLng(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.lat = locationData.geometry.location.lat();
            this.lng = locationData.geometry.location.lng()
            this.center = { lat: this.lat, lng: this.lng },
                this.markers = [{
                    position: {
                        lat: this.lat, lng: this.lng
                    }
                }]
            this.addressLocation = location;
        },
        addEntity: async function () {
            this.isLoading = true;
            if (!this.studioName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter studio name!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.locationName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter location name!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.contactNo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact number!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.addressLocation) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide location address!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.lat && !this.lng) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter map link!"
                });
                this.isLoading = false;
                return false;
            }
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select an image!"
                });
                this.isLoading = false;
                return false;
            }
            let formData = new FormData();
            formData.append('image', this.image);
            formData.append('studioName', this.studioName);
            formData.append('locationName', this.locationName);
            formData.append('email', this.email);
            formData.append('contactNo', this.contactNo);
            formData.append('description', this.description);
            formData.append('mapLink', this.mapLink);
            formData.append('lat', this.lat);
            formData.append('lng', this.lng);
            formData.append('address', this.addressLocation);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.studio.requestURL,
                data: formData,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$refs.addImage.value = "";
                    this.image = "";
                    this.previewImage = "";
                    this.locationName = "";
                    this.studioName = "";
                    this.email = "";
                    this.contactNo = "";
                    this.description = "";
                    this.lat = "";
                    this.lng = "";
                    this.$router.push("/studio");
                    this.isLoading = false;
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
