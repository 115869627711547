import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: "SiteSocialLinks",
    components: {
        Loading,
        VPagination
    },
    data () {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            title: "",
            siteLink: "",
            siteIconClass: "",
            dataList: [],
            singleData: [],
            page: 1,
            limit: 10,
            totalPage: 0
        }
    },
    async created() {
        document.title = "Cosmo Salon - Site Social Links";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.siteSocialLinks.requestURL + params,
            headers: authHeader()
        };
        await this.getDataList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSocialLinks.requestURL + params,
                headers: authHeader()
            };
            await this.getDataList(config);
        },
        getDataList: async function(config) {
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        addEntity: async function() {
            this.isLoading = true;
            if(!this.siteLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert site link!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert site title!"
                });
                this.isLoading = false;
                return false;
            }
            else if(!this.siteIconClass) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert site icon class!"
                });
                this.isLoading = false;
                return false;
            }
            let data = {
                title: this.title,
                siteLink: this.siteLink,
                siteIconClass: this.siteIconClass
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.siteSocialLinks.requestURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', this.$swal.stopTimer)
                          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.siteLink = "";
                    this.siteIconClass = "";
                }
                this.isLoading = false;
                this.updateHandler();
                document.getElementById("closeButton").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteEntity: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "DELETE",
                            url: this.$serverURL + this.$api.siteSocialLinks.requestURL + '/' + id,
                            headers: authHeader()
                        };
                        this.$axios(config).then((response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.updateHandler();
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        },
        setId: function(index) {
            this.singleData = this.dataList[index];
        },
        updateEntity: function(id) {
            this.isLoading = true;
            if(!this.singleData.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert site title!"
                });
                this.isLoading = false;
                return false;
            }
            else if(!this.singleData.siteLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert site link!"
                });
                this.isLoading = false;
                return false;
            }
            else if(!this.singleData.siteIconClass) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert site icon class!"
                });
                this.isLoading = false;
                return false;
            }
            let data = {
                title: this.singleData.title,
                siteLink: this.singleData.siteLink,
                siteIconClass: this.singleData.siteIconClass
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.siteSocialLinks.requestURL + '/' + id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', this.$swal.stopTimer)
                          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.isLoading = false;
                this.updateHandler();
                document.getElementById("buttonClose").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
