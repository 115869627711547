import Loading from "vue-loading-overlay";
// import {authHeader} from "../../../auth";

export default {
    name: "AddData",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            title: null,
            subTitle: null,
            previewImage: null,
            imageFile: null,
            previewLogo: null,
            logoFile: null
        }
    },
    async created() {
        document.title = "Cosmo Salon - Add Data";
    },
    methods: {
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onLogoChange: function (event) {
            this.logoFile = event.target.files[0];
            let input = this.$refs.logo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewLogo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter title!"
                })
                return false;
            }
            if(!this.subTitle) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter sub title!"
                })
                return false;
            }
            if(!this.imageFile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload image file!"
                })
                return false;
            }
            if(!this.logoFile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload logo file!"
                })
                return false;
            }
            return true;
        },
        update: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("title", this.title);
                formData.append("subTitle", this.subTitle);
                formData.append("logo", this.logoFile);
                formData.append("image", this.imageFile);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.sectionThreeCMS.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.previewLogo = "";
                        this.previewImage = "";
                        this.$refs.image.value = null;
                        this.$refs.logo.value = null;
                    }
                    this.isLoading = false;
                    this.$router.push("/section-three-cms");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}