<template>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div>
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h3 class="fw-600 mb-0">Contact List</h3>
                </div>
            </div>
            <div class="table-container">
                <div class="table-section">
                    <div class="table-responsive">
                        <table class="custom_table custom_table_header_grayish">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone No</th>
                                    <th>Professional Type</th>
                                    <th>Description</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="dataList.length > 0">
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.firstname }}</td>
                                    <td>{{ data.lastname }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.phoneNo }}</td>
                                    <td>{{ data.professionalType }}</td>
                                    <td>{{ data.description }}</td>
                                    <td> {{ $filters.formatDate(data['createdAt']) }} </td>
                                    <td>
                                        <button v-on:click="deleteEntity(data['id'])" class="btn btn-sm btn-danger action_btn">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="11">
                                        <div class="alert alert-warning" role="alert" style="text-align: center">
                                            No data found
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
                    <v-pagination v-if="dataList.length > 0" v-model="page" @update:modelValue="updateHandler" :pages="totalPage" :range-size="1" active-color="#212529"></v-pagination>
                </nav>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/contact-list.js"></script>
  
<style scoped>
    @import "../css/contact-list.css";
    @import url("../../../assets/css/pagination.css");
</style>
  